import serviciosMobile1 from "../assets/servicios_serviciosMobile1.png"
import serviciosIPad1 from "../assets/servicios_serviciosIPad1.png"
import serviciosDesk1 from "../assets/servicios_serviciosDesk1.png"

import serviciosMobile2 from "../assets/servicios_serviciosMobile2.png"
import serviciosIPad2 from "../assets/servicios_serviciosIPad2.png"
import serviciosDesk2 from "../assets/servicios_serviciosDesk2.png"

import serviciosMobile3 from "../assets/servicios_serviciosMobile3.png"
import serviciosIPad3 from "../assets/servicios_serviciosIPad3.png"
import serviciosDesk3 from "../assets/servicios_serviciosDesk3.png"

import serviciosMobile4 from "../assets/servicios_serviciosMobile4.png"
import serviciosIPad4 from "../assets/servicios_serviciosIPad4.png"
import serviciosDesk4 from "../assets/servicios_serviciosDesk4.png"

import serviciosMobile5 from "../assets/servicios_serviciosMobile5.png"
import serviciosIPad5 from "../assets/servicios_serviciosIPad5.png"
import serviciosDesk5 from "../assets/servicios_serviciosDesk5.png"

//Covers
import Cover1 from "../assets/Covers/Servicios1.jpg"
import Cover2 from "../assets/Covers/Servicios2.jpg"
import Cover3 from "../assets/Covers/Servicios3.jpg"
import Cover4 from "../assets/Covers/Servicios4.jpg"
import Cover5 from "../assets/Covers/Servicios5.jpg"



import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Servicios",
		boxTitle1:"Plomería y Electricidad en Construcción",
		boxTitle1_1: "Plomería y Electricidad",
		boxTitle1_2: "en Construcción",
		
		boxTitle2: "Diseño, construcción y mantenimiento de piscinas, spa, fuentes etc.",
		boxTitle2_1: "Diseño, construcción y",
		boxTitle2_2: "mantenimiento de",
		boxTitle2_3: "piscinas, spa, fuentes etc.",
		
		boxTitle3: "Mantenimiento e Instalaciones de Plomería y Electricidad",
		boxTitle3_1: "Mantenimiento e Instalaciones",
		boxTitle3_2: "de Plomería y Electricidad",
		
		boxTitle4: "Venta de equipos de Bombeo, Calentadores y Filtrado para servicio de Piscinas",
		boxTitle4_1: "Venta de equipos de Bombeo,",
		boxTitle4_2: "Calentadores y Filtrado",
		boxTitle4_3: "para servicio de Piscinas",
		
		boxTitle5: "Distribución de túneles sanitizantes",
		boxTitle5_1: "Distribución de túneles",
		boxTitle5_2: "sanitizantes",

		seeMore: "Ver más"
	 },
	 en: {
		title: "Services",

		boxTitle1: "Plumbing and Electricity in Construction",
		boxTitle1_1: "Plumbing and Electricity",
		boxTitle1_2: "in Construction",
		
		boxTitle2: "Design, construction and maintenance of swimming pools, spas, fountains, etc.",
		boxTitle2_1: "Design, construction and",
		boxTitle2_2: "maintenance of",
		boxTitle2_3: "swimming pools, spas, fountains, etc.",
		
		boxTitle3: "Maintenance and Installations of Plumbing and Electricity",
		boxTitle3_1: "Maintenance and Installations",
		boxTitle3_2: "of Plumbing and Electricity",
		
		boxTitle4: "Sale of pumping equipment, heaters and filtration",
		boxTitle4_1: "Sale of pumping equipment,",
		boxTitle4_2: "heaters and filtration",
		boxTitle4_3: "for swimming pool service",
		
		boxTitle5: "Distribution of sanitation tunnels",
		boxTitle5_1: "Distribution of sanitation",
		boxTitle5_2: "tunnels",
		seeMore: "See more" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const services = [
		
		{
			name: trans.boxTitle1,
			description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
			image: serviciosDesk1,
			cover: Cover1,
			images: [
				serviciosDesk1,
				serviciosDesk2,
				serviciosDesk3,
				serviciosDesk4,
				serviciosDesk5,
			]
		},		
		{
			name: trans.boxTitle2,
			description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
			image: serviciosDesk2,
			cover: Cover2,
			images: [
				serviciosDesk1,
				serviciosDesk2,
				serviciosDesk3,
				serviciosDesk4,
				serviciosDesk5,
			]
		},		
		{
			name: trans.boxTitle3,
			description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
			image: serviciosDesk3,
			cover: Cover3,
			images: [
				serviciosDesk1,
				serviciosDesk2,
				serviciosDesk3,
				serviciosDesk4,
				serviciosDesk5,
			]
		},		
		{
			name: trans.boxTitle4,
			description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
			image: serviciosDesk4,
			cover: Cover4,
			images: [
				serviciosDesk1,
				serviciosDesk2,
				serviciosDesk3,
				serviciosDesk4,
				serviciosDesk5,
			]
		},		
		{
			name: trans.boxTitle5,
			description: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut",
			image: serviciosDesk5,
			cover: Cover5,
			images: [
				serviciosDesk1,
				serviciosDesk2,
				serviciosDesk3,
				serviciosDesk4,
				serviciosDesk5,
			]
		},

	]
	return services
}

export default Servicios;