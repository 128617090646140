import React, {useEffect} from 'react';

import styles from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import phone from '../../../assets/phone.svg'
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import {
    Flex,
    Box,
    Button,
    Text,
    Image,
    Link
} from 'rebass';


const Toolbar = props => 
{
	
	return(
		<React.Fragment>
			<Flex height="50px" width="100%" justifyContent="flex-end" alignItems="center" bg="#15203C" pr={[15,50,80]} sx={{position:"sticky",top:0,zIndex:50}} >
				<Link href={"tel:624 148 9893"} sx={{cursor: ["pointer"], textDecoration: "none", display: "flex"}}>
					<Image mr="9px" src={phone}/>
					<Text color="#FFFFFF" fontSize="16px">Contáctanos: 624 148 9893</Text>
				</Link>
			</Flex>
			<header className={styles.Toolbar} id="Header">
				<Logo atTop={props.atTop}/>
				<DrawerToggle atTop={props.atTop} clicked={props.toggled} ContactRef={props.ContactRef}/>
				<nav className={styles.DesktopOnly}>
					<NavigationItems ContactRef={props.ContactRef} closed={props.closed} atTop={props.atTop}/>
				</nav>
			</header>
		</React.Fragment>
	);
}


export default Toolbar;