import React, { useRef,useEffect } from 'react';

import styles from './Home.module.css'

import Layout from '../../components/Layout/Layout';
import Footer from '../../sections/Home/Footer/Footer';

import Cover from '../../sections/Home/Cover/Cover';
import Nosotros from '../../sections/Home/Nosotros/Nosotros';
import Servicios from '../../sections/Home/Servicios/Servicios';
import Proyectos from '../../sections/Home/Proyectos/Proyectos';
import Productos from '../../sections/Home/Productos/Productos';
import Clientes from '../../sections/Home/Clientes/Clientes';
import Contacto from '../../sections/Home/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"

const Home = (props) => {

	const ContactoRef = useRef(null)
	const NosotrosRef = useRef(null)
	const ServiciosRef = useRef(null)
	const ProyectosRef = useRef(null)
	const ClientesRef = useRef(null)

	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}
	const toBottomNosotros = () => {
		window.scrollTo({top: NosotrosRef.current.offsetTop -70,behavior: 'smooth',})
	}
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact,
							}}>
				<div className={styles.Home}>
						<div id="Cover" >
							<Cover scrollTo={{
								Nosotros:toBottomNosotros,
								}}/>
						</div>

						<div id="Nosotros" ref={NosotrosRef}  >
							<Nosotros refsTo={{
							Nosotros:NosotrosRef,
							Servicios:ServiciosRef
							}}/>
						</div>

						<div id="Servicios" ref={ServiciosRef} >
							<Servicios />
						</div>

						<div id="Productos">
							<Productos />
						</div>

						<div id="Proyectos" ref={ProyectosRef}>
							<Proyectos refsTo={{
							Proyectos:ProyectosRef,
							Clientes:ClientesRef
							}}/>
						</div>

						<div id="Clientes" ref={ClientesRef}>
							<Clientes />
						</div>

						<div id="Contacto" ref={ContactoRef}>
							<Contacto />
						</div>
						
						<div id="Footer">
							<Footer />
						</div>
				</div>
			</Layout>	
		</Fade>
	)	
};


export default Home;