import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../assets/sendArrow.svg'
import styles from './Proyecto.module.css';
import Layout from '../../components/Layout/Layout';
import ProyectoInfo from '../../sections/Proyectos/Proyecto/Proyecto'
import {FullscreenModal} from '../../components/Modal/FullscreenModal'
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';

import proyectosMobile1 from "../../assets/proyectosMobile1.png"
import proyectosMobile2 from "../../assets/proyectosMobile2.png"
import proyectosMobile3 from "../../assets/proyectosMobile3.png"
import proyectosMobile4 from "../../assets/proyectosMobile4.png"

import proyectosIPad1 from "../../assets/proyectosIPad1.png"
import proyectosIPad2 from "../../assets/proyectosIPad2.png"
import proyectosIPad3 from "../../assets/proyectosIPad3.png"
import proyectosIPad4 from "../../assets/proyectosIPad4.png"

import proyectosDesk1 from "../../assets/proyectosDesk1.png"
import proyectosDesk2 from "../../assets/proyectosDesk2.png"
import proyectosDesk3 from "../../assets/proyectosDesk3.png"
import proyectosDesk4 from "../../assets/proyectosDesk4.png"
import proyects from "../../misc/proyectos"

import { Fade } from "react-awesome-reveal"

const Proyecto = (props) => {
	const ContactoRef = useRef(null)
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Proyecto",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeEveryProject: "Ver todos los proyectos"
	 },
	 en: {
		title: "Pojects",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeEveryProject: "See all projects" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	

	const [displayProyect, setDisplayProyect] = useState(false)
	const [currentProyect, setCurrentProyect] = useState(null)

	useEffect(() => {
		setCurrentProyect(proyects[props.match.params.id])
	},[])

	const showProyect = (e) => {
		setCurrentProyect(proyects[e.currentTarget.id])
		setDisplayProyect(true)
	}

	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70)  ,behavior: 'smooth',})
	}



	return(
		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact,
							}}>
				<div className={styles.Container} id="Proyectos">
					<ProyectoInfo proyect={currentProyect}/>
					<div ref={ContactoRef}>
						<Contacto />
					</div>
					<Footer />
				</div>
			</Layout>
		</Fade>
	);
}

export default Proyecto;