import React from 'react';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming'
import theme from './theme'

import Home from './views/Home/Home';
import Nosotros from './views/Nosotros/Nosotros';
import Servicios from './views/Servicios/Servicios';
import Servicio from './views/Servicio/Servicio';
import Proyectos from './views/Proyectos/Proyectos';
import Proyecto from './views/Proyecto/Proyecto';
import Productos from './views/Productos/Productos';

import { Fade } from "react-awesome-reveal"

function App() {

  return (
        <div className="App">
        	<ThemeProvider theme={theme}>

    	        <Switch>
                    {/*<Fade delay={200}>*/}
                        <Route path="/en" exact component={Home} />
                        <Route path="/es" exact component={Home} />
                        <Route path="/en/nosotros" exact component={Nosotros} />
                        <Route path="/es/nosotros" exact component={Nosotros} />
                        <Route path="/en/servicios" exact component={Servicios} />
                        <Route path="/es/servicios" exact component={Servicios} />
                        <Route path="/en/servicio/:id" exact component={Servicio} />
                        <Route path="/es/servicio/:id" exact component={Servicio} />
                        <Route path="/en/proyectos" exact component={Proyectos} />
                        <Route path="/es/proyectos" exact component={Proyectos} />
                        <Route path="/en/proyecto/:id" exact component={Proyecto} />
                        <Route path="/es/proyecto/:id" exact component={Proyecto} />
                        <Route path="/en/productos" exact component={Productos} />
                        <Route path="/es/productos" exact component={Productos} />
                        <Redirect path="*" to="/es" /> 
                    {/*</Fade>*/}
    	        </Switch>
          
      		</ThemeProvider>
        </div>
  );
}

export default App;
