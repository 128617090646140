import React from 'react';
import LocalizedStrings from 'react-localization';
import {useHistory, NavLink} from 'react-router-dom';
import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem'
import LangSelect from '../../LangSelect/LangSelect'

const NavigationItems = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		Inicio: "Inicio",
		Nosotros: "Nosotros",
		Servicios: "Servicios",
		Proyectos: "Proyectos",
		Productos: "Productos",
		Contacto: "Contacto",
	 },
	 en: {
		Inicio: "Home",
		Nosotros: "About us",
		Servicios: "Services",
		Proyectos: "Projects",
		Productos: "Products",
		Contacto: "Contact",
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const navigationClick = (nav) =>{
		history.push(`/${urlLang}${nav}`)
	}

	const onContactClick = (event) => {
		console.log("NAVIGATION",props.ContactRef,props.closed)
		props.ContactRef();
		props.closed();
	}




	return(
		<ul className={styles.NavigationItems}>
			<li className={styles.NavigationItem} onClick={() => navigationClick("")}>
				<NavLink
				className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
				exact
				activeClassName={styles.active} 
				to={`/${urlLang}`}>{trans.Inicio}</NavLink>
			</li>
			<li className={styles.NavigationItem} onClick={() => navigationClick("/nosotros")}>
				<NavLink
				className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
				exact
				activeClassName={styles.active} 
				to={`/${urlLang}/nosotros`}>{trans.Nosotros}</NavLink>
			</li>
			<li className={styles.NavigationItem} onClick={() => navigationClick("/servicios")}>
				<NavLink
				className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
				
				activeClassName={styles.active} 
				to={`/${urlLang}/servicios`}>{trans.Servicios}</NavLink>
			</li>
			<li className={styles.NavigationItem} onClick={() => navigationClick("/productos")}>
				<NavLink
				className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
				exact
				activeClassName={styles.active} 
				to={`/${urlLang}/productos`}>{trans.Productos}</NavLink>
			</li>
			<li className={styles.NavigationItem} onClick={() => navigationClick("/proyectos")}>
				<NavLink
				className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2}
				exact
				activeClassName={styles.active} 
				to={`/${urlLang}/proyectos`}>{trans.Proyectos}</NavLink>
			</li>
			<li className={styles.NavigationItem}>
				<div className={(props.atTop && !window.innerWidth < 1100) ? styles.NavItem : styles.NavItem2} onClick={onContactClick} >{trans.Contacto}</div>
			</li>
			<li className={styles.NavigationItem}>
				<LangSelect HomeHeaderWhite={(props.atTop && !window.innerWidth < 1100)} HomeHeaderBlack={!(props.atTop && !window.innerWidth < 1100)}/>
			</li>
		</ul>
	);
}

export default NavigationItems;