import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import styles from './Cover.module.css';
import coverMobile1 from "../../../assets/nosotros_coverMobile1.png"
import coverIPad1 from "../../../assets/nosotros_coverIPad1.png"
import coverDesk1 from "../../../assets/nosotros_coverDesk1.png"
import coverMobile2 from "../../../assets/nosotros_coverMobile2.png"
import coverIPad2 from "../../../assets/nosotros_coverIPad2.png"
import coverDesk2 from "../../../assets/nosotros_coverDesk2.png"   

const Cover = (props) => {

	return(
		<Flex flexDirection={["column"]} height="100%">
			<Flex flexDirection="column"  mb={[0,100]}>
				<div className={styles.Container} id="Cover" style={{backgroundImage:`url(${props.cover})`,backgroundSize:"cover"}}>
					<div className={"MaxDimensions"} style={{position:"relative"}}>
						<Flex justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="100%">
							<Text
									sx={{textShadow:"#00000052 0 9px 6px"}}
									textAlign="center"
									mb={["8px", "20px"]}
									fontWeight={["900", "700", "900"]} 
									textAlign="left" 
									fontSize={[30, 40, 50]} 
									color="white">
									{props.title}
							</Text>
							<Text
									textAlign="center"
									mb={[90, 150]}
									fontWeight={["400"]} 
									textAlign="left" 
									fontSize={[20, 30, 25]} 
									color="white">
									{props.subtitle}
							</Text>
							<Box className={styles.IPadDeskAbsoluteCard} bg="#F4F5F7" width="90%" display={["none","block"]} >
								<Flex px={50} minHeight="200px" alignItems="center" justifyContent="center">
									<Text 
										textAlign="center" 
										color="#2E333E"
										fontSize={[18,20]}>{props.description}</Text>
								</Flex>
							</Box>
						</Flex>
					</div>
				</div>
			</Flex>
			<Flex flexDirection="column">
				<Flex display={["flex","none","none"]} bg="#F4F5F7" minHeight={[370]} alignItems="center" justifyContent="center">
					<div className={styles.Container} id="Cover">
						<div className={"MaxDimensions"} style={{position:"relative"}}>
							<Text 
								textAlign="left" 
								color="#2E333E"
								fontSize={[20]}>{props.description}</Text>
						</div>
					</div>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default Cover;