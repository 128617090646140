import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Nosotros.module.css';
import nosotrosMobile from "../../../assets/nosotrosMobile.png"
import nosotrosIPad from "../../../assets/nosotrosIPad.png"
import nosotrosDesk from "../../../assets/nosotrosDesk.png"
import nosotros from "../../../assets/Nostros.png"
import arrowDown from "../../../assets/arrowDown.svg"
import waterMark from "../../../assets/waterMark.svg"
import { motion } from "framer-motion"

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    
  },

};

const Nosotros = (props) => {
	const history = useHistory();
	const [isVisible,setIsVisible] = useState(false)

	let trans = new LocalizedStrings({
	 es:{
		title: "Nosotros",
		subtitle: "Somos una empresa orgullosamente creada en Cabo San Lucas, BCS. Con más de 16 años de experiencia y un equipo de más de 90 personas, hemos concretado múltiples proyectos exitosos, colaborando con arquitectos y constructoras reconocidas.",
		seeMore: "Conócenos"
	 },
	 en: {
		title: "About us",
		subtitle: "We are a proudly created company in Cabo San Lucas, BCS. With more than 16 years of experience and a team of more than 90 people, we have completed multiple successful projects, collaborating with renowned architects and construction companies.",
		seeMore: "See more" 
	 }
	});

	const handleScroll = e => {
			// console.log("here",props.refsTo.Nosotros.current.offsetTop,"finish",props.refsTo.Servicios.current.offsetTop-250,"current",window.scrollY)
		if (window.scrollY >= 10 && window.scrollY < (props.refsTo.Servicios.current.offsetTop -250)) {
			setIsVisible(true);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		}
	},[]);

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);




	return(
			<div className={styles.Container} id="Nosotros" onClick={() => {setIsVisible(true)}}>
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				
					<Flex flexDirection={["column","column","row"]} >
						<Flex sx={{backgroundImage: `url(${waterMark})`,backgroundSize:"232px",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} flexDirection="column" width={["100%","100%","50%"]} justifyContent={["flex-start","flex-start","center"]}>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }}>
							<Flex justifyContent="flex-start" alignItems="center">
								<Text
									fontWeight="900" 
									textAlign="left" 
									fontSize={[45,65]} 
									color="#2E333E">{trans.title}</Text>
							</Flex>
							</motion.div>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }} >
							<Flex  flexDirection="column" sx={{borderLeft:"1px solid #2E333E"}} pl={[15]} mt={[30]} mb={[150,150,70]} justifyContent="center" alignItems="flex-start">
								<Text 
									maxWidth={["100%","100%",466]}
								 	mb={[30]}
									textAlign="left" 
									lineHeight="28px" 
									color="#2E333E"
									fontSize={[18,20]}>{trans.subtitle}</Text>
									<button className={styles.Button} onClick={() => history.push(`/${urlLang}/nosotros`)}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div style={{fontWeight:700}} className="hoverWhite">{trans.seeMore}</div>
										</div>
									</button>
							</Flex>
							</motion.div>
						</Flex>
						<motion.div className={styles.animatedDiv} 
							style={{position:"relative",justifyContent:"center",display:"flex",padding:"40px 0",}}
							initial={{ opacity: 0, y: 1000 }} animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 1000}}
							transition={{ duration: 1 }}>
							<Box className={styles.AbsoluteBox} />
							<Image height="auto" sx={{zIndex:2,objectFit:"cover"}}  width="100%" src={nosotros}/>
						</motion.div>
					</Flex>
			</div>
			</div>
	);
}

export default Nosotros;