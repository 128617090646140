import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import styles from './Contacto.module.css';
import LocalizedStrings from 'react-localization';
import { useHistory } from 'react-router-dom';

import Input from '../../../components/UI/Input/Input';
import  * as util from './util';

import mobile from '../../../assets/mobile.svg'
import email from '../../../assets/email.svg'
import phone from '../../../assets/phone.svg'

import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';

import isotipo from '../../../assets/isotipoContacto.svg'
import send from '../../../assets/sendArrowColor.svg'
import instagram from '../../../assets/instagram.svg'
import facebook from '../../../assets/facebook.svg'
import twitter from '../../../assets/twitter.svg'


const Contacto = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		text: "Contáctanos", 
		name: "Nombre",
		message: "Mensaje",
		send:  "Enviar",
		title: "Enviado",
	    textSwal: "Nos pondremos en contacto contigo",
	    
	 },
	 en: {
		text: "Contact us", 
		name: "Name",
		message: "Message",
		send:   "Send",
		title: "Sent",
	    textSwal: "We will contact you",
	     
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [form, setForm] = useState({
			name: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: trans.name,
					},
					value: '',
					validation: {
						required: true,
						minLength: 4,
					},
					valid:false,
					touched:false,
					errorType: []
				},
			email: {
					elementType: 'input',
					elementConfig: {
						type: 'email',
						placeholder: 'Email',
					},
					value: '',
					validation: {
						required: true,
						isEmail: true
					},
					valid:false,
					touched:false,
					errorType: [],
				},
			message: {
					elementType: 'textarea',
					elementConfig: {
						type: 'text',
						placeholder: trans.message,
					},
					value: '',
					validation: {
						required: true,
						minLength: 10,
					},
					valid:false,
					touched:false,
					errorType: []
				},

	});


	const [formIsValid, setFormIsValid] = useState(false);

	const submitHandler = (event) => {
		event.preventDefault();		
		if(formIsValid){
			// axios.post('https://getform.io/f/bb710f92-c37c-43a3-9329-91951ce6dfb8', {
	  //           name: form.name.value,
	  //           email: form.email.value,
	  //           message: form.message.value,
		 //    })
		 //    .then((response) => {
		 //        Swal.fire({
			// 	  title: trans.title,
			// 	  text: trans.textSwal,
			// 	  icon: 'success',
			// 	});
			// 	setForm({...form,
			// 		name:{...form.name,value:""},
			// 		email:{...form.email,value:""},
			// 		message:{...form.message,value:""}})
		 //    })
		 //    .catch((error) => {
		 //        console.log(error);
		 //    });
		 Swal.fire({
			  title: trans.title,
			  text: trans.textSwal,
			  icon: 'success',
			});
		}
	}

	const formElementsArray = [];
		for (let key in form){
			formElementsArray.push({
				id:key,
				config: form[key]
			});
		}

	const _form = formElementsArray.map(formElement => (
						<div className={styles.InputEmailContainer} key={"div"+formElement.id}>
							<Input 
							key={formElement.id}
							changed={(event) => util.inputChangeHandler(event,formElement.id,form,setForm,setFormIsValid)}
							elementType={formElement.config.elementType}
							elementConfig={formElement.config.elementConfig}
							invalid={!formElement.config.valid}
							errorsArray={formElement.config.errorType}
							touched = {formElement.config.touched}
							shouldValidate={formElement.config.validation}
							value={formElement.config.value} />
						</div>
					));
	return(
		<div className={styles.Container} id="Contacto">
			<div className={"MaxDimensions"}>
				
				<div className={styles.Header} >
					<Flex justifyContent={"center"} alignItems={"center"} mb={65}>
						<Image width={[105]} height="auto" src={isotipo} alt=""/>
					</Flex>
					<div className={styles.HeaderText}>{trans.text}</div>
				</div>
				<div className={styles.SocialFormContainer}>
						<div className={styles.MainInfoContainer} >
							<div className={styles.ImgContainer}>
								<div className={styles.imgText} style={{marginBottom:40,marginTop:20,fontWeight:"bold"}}>Idea Los Cabos S DE RL DE CV</div>
								<div className={styles.imgBox}>
								<div style={{width:35,marginRight:10,boxSizing:"border-box"}}>
									<img src={phone} alt="" className={styles.imgIcon}/>
								</div>
									<div className={styles.imgText}>+624 231234 1234</div>
								</div>
								<div className={styles.imgBox}>
								<div style={{width:35,marginRight:10,boxSizing:"border-box"}}>
									<img src={mobile} alt="" className={styles.imgIcon} />
								</div>
									<div className={styles.imgText}>+624 231234 1234</div>
								</div>
								<div className={styles.imgBox}>
								<div style={{width:35,marginRight:10,boxSizing:"border-box"}}>
									<img src={email} alt="" className={styles.imgIcon}/>
								</div>
									<div className={styles.imgText}>contacto@idealoscabos.com</div>
								</div>
							</div>
						</div>
						<div className={styles.FormContainer} >
							<form className={styles.Form} onSubmit={submitHandler}>
								<div className={styles.InputEmailContainer}>
									{_form}
								</div>
								<div className={styles.SocialContainer}>
									<img onClick={() => {window.open('https://www.instagram.com/glitchcornerstudios/', '_blank')}} style={{cursor:"pointer",marginRight:"35px"}} className="getBig" src={instagram} alt=""/>
									<img onClick={() => {window.open('https://www.facebook.com/Glitch-Corner-Studios-860836740962432/', '_blank')}} style={{cursor:"pointer"}} className="getBig" src={facebook} alt=""/>
								</div>
								<div className={styles.ButtonContainer}>
									<button className={styles.Button}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div style={{fontSize:"21px"}} className="hoverWhite">{trans.send}</div>
											<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
										</div>
									</button>
								</div>
							</form>
						</div>
				</div>
			</div>
		</div>
	);
}

export default Contacto;