import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Proyectos.module.css';
import proyectos1 from "../../../assets/ArqManriquez/3.jpg"
import proyectos2 from "../../../assets/proyectos2.png"
import proyectos3 from "../../../assets/CasaCloobek/Portada1.jpg"
import proyectos4 from "../../../assets/proyectos4.png"
import { motion } from "framer-motion"


const Proyectos = (props) => {
	const history = useHistory();
	const [isVisible,setIsVisible] = useState(false)

	let trans = new LocalizedStrings({
	 es:{
		title: "Proyectos",
		subtitle: "Buscamos siempre superar las expectativas de nuestros clientes, mejorando continuamente nuestro desempeño.",
		seeEveryProject: "Ver todos los proyectos"
	 },
	 en: {
		title: "Pojects",
		subtitle: "We always seek to exceed the expectations of our customers, continuously improving our performance.",
		seeEveryProject: "See all projects" 
	 }
	});

	const handleScroll = e => {
		let DomHeight = props.refsTo.Proyectos.current.offsetTop - 874
		let DomMaxHeight = props.refsTo.Clientes.current.offsetTop
		if(window.innerWidth >= 700 && window.innerWidth <= 1099){
			DomHeight = props.refsTo.Proyectos.current.offsetTop - 989
			DomMaxHeight = props.refsTo.Clientes.current.offsetTop - 989
		}
		else if(window.innerWidth >= 0 && window.innerWidth <= 699){
			DomHeight = props.refsTo.Proyectos.current.offsetTop - 640
			DomMaxHeight = props.refsTo.Clientes.current.offsetTop - 640
		}
		console.log("here",DomHeight,"finish",DomMaxHeight,"current",window.scrollY)
		if (window.scrollY >= DomHeight && window.scrollY < DomMaxHeight) {
			setIsVisible(true);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		}
	},[]);

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);


	return(
			<div className={styles.Container} id="Proyectos">
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				<Flex flexDirection={["column"]} >
					<Flex flexDirection={["column","column","row"]} mb={[0,0,90]} alignItems={["unset","unset","center"]}>
						<motion.div className={styles.AnimatedFromTop} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }}>
								<Text
									fontWeight="900" 
									textAlign="left" 
									fontSize={[45,65,70]} 
									color="#2E333E">{trans.title}</Text>
						</motion.div>
						<motion.div className={styles.AnimatedFromBottom} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 1000}} transition={{ duration: 1 }}>
								<Text 
									textAlign="left" 
									color="#2E333E"
									lineHeight="28px" 
									fontSize={[18,25]}>{trans.subtitle}</Text>
								<Flex mt={[58]}>
									<button className={styles.Button} onClick={() => history.push(`/${urlLang}/proyectos`)}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div className="hoverWhite">{trans.seeEveryProject}</div>
										</div>
									</button>
								</Flex>
						</motion.div>
					</Flex>
					<Flex flexDirection={["column"]}>
						<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: isVisible ? 1 : 0, x: isVisible ? 0 : -1000}} transition={{ duration: 1 }}>
							<Flex flexDirection={["column","row"]} justifyContent={["space-between"]} mb={[0,"20px"]}>
								<Box className="ZoomInContainer" mb={["80px",0]} height={[260,284,464]} width={['calc(100% + 30px)',"56%"]} mr={["-15px","1%"]} ml={["-15px","0"]}>
										<Flex flexDirection="column" justifyContent="center" alignItems={["center","flex-start"]} bg="white" pl={[0,"10px"]} width={[280,242]} height={[72,72,80]} className={styles.AbsoluteTextBox+" ZoomSibling"}>
											<Text color="#15203C" fontWeight="700" fontSize={["18px","18px","30px"]}>Mariquez Arq.</Text>
										</Flex>
									<Box onClick={() => history.push(`/${urlLang}/proyecto/0`)}  className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos1})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
									</Box>
								</Box>
								<Box className="ZoomInContainer" mb={["80px",0]} height={[260,284,464]} width={['calc(100% + 30px)',"42%"]} ml={["-15px","1%"]} mr={["-15px","0"]}>
										<Flex flexDirection="column" justifyContent="center" alignItems={["center","flex-start"]} bg="white" pl={[0,"10px"]} width={[280,242]} height={[72,72,80]} className={styles.AbsoluteTextBox+" ZoomSibling"}>
											<Text color="#15203C" fontWeight="700" fontSize={["18px","18px","30px"]}>Casa Lorenzen</Text>
										</Flex>
									<Box onClick={() => history.push(`/${urlLang}/proyecto/5`)}   className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos2})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
									</Box>
								</Box>
							</Flex>
						</motion.div>
						<motion.div style={{zIndex:10}} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 1000}} transition={{ duration: 1 }}>
							<Flex flexDirection={["column","row"]} justifyContent={["space-between"]}>
								<Box className="ZoomInContainer" mb={["80px",0]} height={[260,284,464]} width={['calc(100% + 30px)',"42%"]} ml={["-15px","0"]} mr={["-15px","1%"]}>
										<Flex flexDirection="column" justifyContent="center" alignItems={["center","flex-start"]} bg="white" pl={[0,"10px"]} width={[280,242]} height={[72,72,80]} className={styles.AbsoluteTextBox+" ZoomSibling"}>
											<Text color="#15203C" fontWeight="700" fontSize={["18px","18px","30px"]}>Casa Cloobek</Text>
										</Flex>
									<Box onClick={() => history.push(`/${urlLang}/proyecto/2`)}  className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos3})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
									</Box>
								</Box>
								<Box className="ZoomInContainer" mb={["80px",0]} height={[260,284,464]} width={['calc(100% + 30px)',"56%"]}  ml={["-15px","1%"]} mr={["-15px","0"]}>
										<Flex flexDirection="column" justifyContent="center" alignItems={["center","flex-start"]} bg="white" pl={[0,"10px"]} width={[280,242]} height={[72,72,80]} className={styles.AbsoluteTextBox+" ZoomSibling"}>
											<Text color="#15203C" fontWeight="700" fontSize={["18px","18px","30px"]}>Casa El Faro</Text>
										</Flex>
									<Box onClick={() => history.push(`/${urlLang}/proyecto/3`)}  className="ZoomIn" sx={{cursor:"pointer",position:"relative",backgroundImage:[`url(${proyectos4})`],backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}} >
									</Box>
								</Box>
							</Flex>
						</motion.div>
					</Flex>
				</Flex>
			</div>
			</div>
	);
}

export default Proyectos;