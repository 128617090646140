import React,{ useState, useEffect } from 'react';

import styles from './Layout.module.css'
import Toolbar from '../Navigation/Toolbar/Toolbar'

import SideDrawer from '../Navigation/SideDrawer/SideDrawer'



const Layout = (props) => {
	const [showSideDrawer, setShowSideDrawer] = useState(false);
	const [atTop, setAtTop] = useState(true);

	const handleScroll = e => {
		if (window.scrollY > 0) {
			document.getElementById("Header").classList.add("whiteHeader");
			setAtTop(false)
		}
		else{
			document.getElementById("Header").classList.remove("whiteHeader");
			setAtTop(true)
		}
	}

	useEffect(() => {
		window.scrollTo(0,0)
		window.addEventListener('scroll', handleScroll);
		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
		}
	},[])


	


	const sideDrawerClosedHandler = () => {
		setShowSideDrawer(false);
	}


	const sideDrawerToggledHandler = () => {
		setShowSideDrawer(!showSideDrawer);
	}


	return(
		<React.Fragment>
			
			<Toolbar atTop={atTop} ContactRef={props.scrollTo.Contacto} closed={sideDrawerClosedHandler} toggled={sideDrawerToggledHandler}/> 
			<SideDrawer open={showSideDrawer} closed={sideDrawerClosedHandler} ContactRef={props.scrollTo.Contacto}/>
			<main className={styles.Content}>
				{props.children}
			</main>
		</React.Fragment>
	);
}


export default Layout;