import React, { useState, useEffect } from 'react';

import { Box, Image} from 'rebass';

export const ItemLightBox = (props) => {

    const item = props.element;

    const image_style = {
    	zIndex:2,
    	objectFit:'cover',
    	transition:'all 1s',
    	position:'absolute',
        left:0,
    	'&:hover': {
    		transform: 'scale(1.2)',
    		'-moz-transform': 'scale(1.2)',
    		'-webkit-transform': 'scale(1.2)',
    		'-o-transform': 'scale(1.2)',
    		'-ms-transform': 'scale(1.2)',
    	}
    }

    const backdrop = {
    	backgroundRepeat: "no-repeat",
    	backgroundSize: 'cover',
    	backgroundPosition: 'center',
    	backgroundImage: `url(${item.src})`,
    	opacity:.5,
    	zIndex:1,
    }

    return (
        <Box id={item.index} onClick={props.callback} height="100%" width="100%" sx={{
            borderRadius: 0,
            boxShadow: '0 0 10px rgba(0, 0, 0, .25)',
            overflow:'hidden',
            position:'relative',
        }}>
            <Image left="0" height="100%" width="100%" src={item.src} sx={image_style}/>
        </Box>
    );
}