import React, { useRef,useEffect } from 'react';

import styles from './Nosotros.module.css'

import Layout from '../../components/Layout/Layout';
import Cover from '../../sections/Nosotros/Cover/Cover';
import Historia from '../../sections/Nosotros/Historia/Historia';
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"

const Nosotros = (props) => {
	const ContactoRef = useRef(null)
	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70) ,behavior: 'smooth',})
	}
	
	return(

		<Fade delay={200}>
			<Layout scrollTo={{
							Contacto:toBottomContact,
							}}>
				
					<div id="Cover">
					<Cover />
					</div>
					<div id="Historia">
					<Historia />		
					</div>
					<div id="Contacto"  ref={ContactoRef}>
					<Contacto />
					</div>
					<div id="Footer">
					<Footer />	
					</div>
			</Layout>	
		</Fade>
	)	
};


export default Nosotros;