import React from 'react';
import styles from './Input.module.css';

const input = (props) => {
	let inputElement = null;
	const inputClasses = [styles.InputElement];
	let validationError = null;
	let errorMessage = "";
	if(props.errorsArray){
		props.errorsArray.map((err) => {
			if(errorMessage !== ""){
	 			errorMessage = errorMessage + ',' + err;
			}
			else{
				var uppercaseFirstLetter = err.charAt(1).toUpperCase() + err.slice(2);
				errorMessage = errorMessage + uppercaseFirstLetter;
			}
			return true;
		})
	}
	else{
		errorMessage = "Valor Incorrecto"
	}
	// props.errorsArray ?
	// 	props.errorsArray.map((err) => {
	// 		errorMessage = errorMessage + err;
	// 	})
	// :
	// 	null;

	if(props.invalid && props.shouldValidate && props.touched) {
		inputClasses.push(styles.Invalid);
		validationError = <p className={styles.ValidationError}>{errorMessage}</p>; 
	}

	switch (props.elementType) {
		case('input'): 
			inputElement = <div className={styles.Input}>
				 <input 
				className={inputClasses.join(' ')} 
				{...props.elementConfig} value={props.value}
				onChange={props.changed} />
				{validationError}
				</div>
			break;
		case('textarea'): 
			inputElement = 
			 <div className={styles.Input}>
				<textarea 
				className={inputClasses.join(' ')} 
				{...props.elementConfig} value={props.value}
				onChange={props.changed} />
				{validationError}
				</div>
			break;
		case('select'): 
			inputElement = (
				<select 
					className={inputClasses.join(' ')} 
					value={props.value}
					onChange={props.changed}>
						{props.elementConfig.options.map(option => (				
							<option
								key={option.value} 
								value={option.value}>
								{option.displayValue}
							</option>
						))}
				</select>
			);
		break;
		default:
			inputElement = <input 
				className={inputClasses.join(' ')} 
				{...props.elementConfig} value={props.value}
				onChange={props.changed} />;
	}


	return (
		<div className={styles.Input}>
			<label htmlFor="">{props.label}</label>
			{inputElement}
		</div>
);


}


export default input;