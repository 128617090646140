import proyectosMobile1 from "../assets/proyectosMobile1.png"
import proyectosMobile2 from "../assets/proyectosMobile2.png"
import proyectosMobile3 from "../assets/proyectosMobile3.png"
import proyectosMobile4 from "../assets/proyectosMobile4.png"

import proyectosIPad1 from "../assets/proyectosIPad1.png"
import proyectosIPad2 from "../assets/proyectosIPad2.png"
import proyectosIPad3 from "../assets/proyectosIPad3.png"
import proyectosIPad4 from "../assets/proyectosIPad4.png"

import proyectosDesk1 from "../assets/proyectosDesk1.png"
import proyectosDesk2 from "../assets/proyectosDesk2.png"
import proyectosDesk3 from "../assets/proyectosDesk3.png"
import proyectosDesk4 from "../assets/proyectosDesk4.png"

//ArqArcher
import ArqArcherPortada from "../assets/ArqArcher/Portada.jpg"
import ArqArcher2 from "../assets/ArqArcher/2.jpg"
import ArqArcher3 from "../assets/ArqArcher/3.jpg"
import ArqArcher4 from "../assets/ArqArcher/4.jpg"
import ArqArcher5 from "../assets/ArqArcher/5.jpg"
import ArqArcher6 from "../assets/ArqArcher/6.jpg"

//ArqManriquez
import ArqManriquezPortada from "../assets/ArqManriquez/Portada1.jpg"
import ArqManriquez2 from "../assets/ArqManriquez/2.jpg"
import ArqManriquez3 from "../assets/ArqManriquez/3.jpg"
import ArqManriquez4 from "../assets/ArqManriquez/4.jpg"
import ArqManriquez5 from "../assets/ArqManriquez/5.jpg"
import ArqManriquez6 from "../assets/ArqManriquez/6.jpg"

//CasaCloobek
import CasaCloobekPortada from "../assets/CasaCloobek/Portada1.jpg"
import CasaCloobek2 from "../assets/CasaCloobek/2.jpg"
import CasaCloobek3 from "../assets/CasaCloobek/3.jpg"


//CasaElFaro
import CasaElFaroPortada from "../assets/CasaElFaro/Portada1.jpg"
import CasaElFaro2 from "../assets/CasaElFaro/2.jpg"
import CasaElFaro3 from "../assets/CasaElFaro/3.jpg"
import CasaElFaro4 from "../assets/CasaElFaro/4.jpg"

//CasaForeman
import CasaForemanPortada from "../assets/CasaForeman/Portada-1.jpg"
import CasaForeman2 from "../assets/CasaForeman/2.jpg"
import CasaForeman3 from "../assets/CasaForeman/3.jpg"
import CasaForeman4 from "../assets/CasaForeman/4.jpg"

//CasaLorenzen
import CasaLorenzenPortada from "../assets/CasaLorenzen/Portada1.jpg"
import CasaLorenzen2 from "../assets/CasaLorenzen/2.jpg"
import CasaLorenzen3 from "../assets/CasaLorenzen/3.jpg"
import CasaLorenzen4 from "../assets/CasaLorenzen/4.jpg"
import CasaLorenzen5 from "../assets/CasaLorenzen/5.jpg"

//CasaRush
import CasaRushPortada from "../assets/CasaRush/Portada1.jpg"
import CasaRush2 from "../assets/CasaRush/2.jpg"
import CasaRush3 from "../assets/CasaRush/3.jpg"


//CostaPalma
import CostaPalmaPortada from "../assets/CostaPalma/Portada1.jpg"
import CostaPalma2 from "../assets/CostaPalma/2.jpg"
import CostaPalma3 from "../assets/CostaPalma/3.jpg"


//Jasson
import JassonPortada from "../assets/Jasson/Portada-1.jpg"
import Jasson2 from "../assets/Jasson/2.jpg"
import Jasson3 from "../assets/Jasson/3.jpg"
import Jasson4 from "../assets/Jasson/4.jpg"
import Jasson5 from "../assets/Jasson/5.jpg"
import Jasson6 from "../assets/Jasson/6.jpg"

//Made
import MadePortada from "../assets/Made/Portada1.jpg"
import Made2 from "../assets/Made/2.jpg"

//Mirdina
import MirdinaPortada from "../assets/Mirdina/Portada1.jpg"
import Mirdina2 from "../assets/Mirdina/2.jpg"
import Mirdina3 from "../assets/Mirdina/3.jpg"

//Ramsey
import RamseyPortada from "../assets/Ramsey/Portada1.jpg"
import Ramsey2 from "../assets/Ramsey/2.jpg"
import Ramsey3 from "../assets/Ramsey/3.jpg"

//Covers

import ArcherArqCover from "../assets/Covers/ArcherArq.jpg"

import MariquezArqCover from "../assets/Covers/MariquezArq.jpg"

import CasaCloobekCover from "../assets/Covers/CasaCloobek.jpg"

import CasaElFaroCover from "../assets/Covers/CasaElFaro.jpg"

import CasaForemanCover from "../assets/Covers/CasaForeman.jpg"

import CasaLorenzenCover from "../assets/Covers/CasaLorenzen.jpg"

import CasaRushCover from "../assets/Covers/CasaRush.jpg"

import CostaPalmaCover from "../assets/Covers/CostaPalma.jpg"

import JassonCover from "../assets/Covers/Jasson.jpg"

import MadeCover from "../assets/Covers/Made.jpg"

import MirdinaCover from "../assets/Covers/Mirdina.jpg"

import CasaRamseyCover from "../assets/Covers/CasaRamsey.jpg"

const proyects = [
		{
			id:0,
			client:'Arq. Archer',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:ArqArcherPortada,
			cover:ArcherArqCover,
			images:[
				ArqArcherPortada,
				ArqArcher2,
				ArqArcher3,
				ArqArcher4,
				ArqArcher5,
				ArqArcher6,
			]
		},
		{
			id:1,
			client:'Arq. Manriquez',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:ArqManriquezPortada,
			cover:MariquezArqCover,
			images:[
				ArqManriquezPortada,
				ArqManriquez2,
				ArqManriquez3,
				ArqManriquez4,
				ArqManriquez5,
				ArqManriquez6,
			]
		},
		{
			id:2,
			client:'Casa Cloobek',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CasaCloobekPortada,
			cover:CasaCloobekCover,
			images:[
				CasaCloobekPortada,
				CasaCloobek2,
				CasaCloobek3,
			]
		},
		{
			id:3,
			client:'Casa El Faro',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CasaElFaroPortada,
			cover:CasaElFaroCover,
			images:[
				CasaElFaroPortada,
				CasaElFaro2,
				CasaElFaro3,
				CasaElFaro4,
			]
		},
		{
			id:4,
			client:'Casa Foreman',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CasaForemanPortada,
			cover:CasaForemanCover,
			images:[
				CasaForemanPortada,
				CasaForeman2,
				CasaForeman3,
				CasaForeman4,

			]
		},
		{
			id:5,
			client:'Casa Lorenzen',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CasaLorenzenPortada,
			cover:CasaLorenzenCover,
			images:[
				CasaLorenzenPortada,
				CasaLorenzen2,
				CasaLorenzen3,
				CasaLorenzen4,
				CasaLorenzen5,
			]
		},
		{
			id:6,
			client:'Casa Rush',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CasaRushPortada,
			cover:CasaRushCover,
			images:[
				CasaRushPortada,
				CasaRush2,
				CasaRush3,
			]
		},
		{
			id:7,
			client:'Costa Palma',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:CostaPalmaPortada,
			cover:CostaPalmaCover,
			images:[
				CostaPalmaPortada,
				CostaPalma2,
				CostaPalma3,
			]
		},
		{
			id:8,
			client:'Jasson',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:JassonPortada,
			cover:JassonCover,
			images:[
				JassonPortada,
				Jasson2,
				Jasson3,
				Jasson4,
				Jasson5,
				Jasson6,
			]
		},
		{
			id:9,
			client:'Made',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:MadePortada,
			cover:MadeCover,
			images:[
				MadePortada,
				Made2,

			]
		},
		{
			id:10,
			client:'Mirdina',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:MirdinaPortada,
			cover:MirdinaCover,
			images:[
				MirdinaPortada,
				Mirdina2,
				Mirdina3,

			]
		},
		{
			id:11,
			client:'Ramsey',
			type:'',
			description:'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod',
			image:RamseyPortada,
			cover:CasaRamseyCover,
			images:[
				RamseyPortada,
				Ramsey2,
				Ramsey3,

			]
		},
		
	]

export default proyects