import React, { useRef,useEffect } from 'react';

import styles from './Proyectos.module.css'

import Layout from '../../components/Layout/Layout';
import Cover from '../../sections/Proyectos/Cover/Cover';
import ProyectosList from '../../sections/Proyectos/Proyectos/Proyectos';
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"
const Proyectos = (props) => {
	const ContactoRef = useRef(null)
	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70)  ,behavior: 'smooth',})
	}
	
	return(

		<Fade delay={200}>
			<Layout scrollTo={{
				Contacto:toBottomContact,
			}}>
				<div className={styles.Proyectos}>
					<div id="Cover">
					<Cover />
					</div>
					<div id="ProyectosList">
					<ProyectosList />
					</div>
					<div id="Contacto" ref={ContactoRef}>
					<Contacto />
					</div>
					<div id="Footer">
					<Footer />	
					</div>
				</div>
			</Layout>
		</Fade>
	)	
};


export default Proyectos;