import React, { useEffect, useState } from 'react';
import {GridLightBox} from '../../../components/LightBox/grid'
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';

import Cover from './Cover';
import styles from './Servicio.module.css';


const Servicio = (props) => {

	const [gallery, setGallery] = useState([])
	console.log("not_proyect",gallery)

	useEffect(() => {
		if(props.service){
			let _gallery = []
			console.log("service")
			props.service.images.map((file, index) => {
				_gallery.push({
					src: file,
					index:index
		    	})
			})
			setGallery(_gallery)
		}
	}, [props.service])

	return(
		<React.Fragment>
			<Cover title={props.service?props.service.name:''} subtitle="" description={props.service?props.service.description:''} cover={props.service ? props.service.cover : null}/>
			<div className={styles.Container} id="Proyectos">
				<div className={"MaxDimensions"} style={{position:"relative"}}>
					<Flex flexDirection={["column"]} >
						<Flex flexDirection={["column"]}>
							<Flex flexDirection={["column","row"]} justifyContent={["flex-start"]} flexWrap="wrap">
								{props.service?
									// props.service.images.map((image,i)=>(
									// 	<Box key={"box"+i} mb={[36, 36]} py={[21, 21, 25]} px={[0,9,11]} width={[1, 1/2, 1/3]} sx={{position:"relative"}}>
									// 		<Box width="100%" key={"boxInner"+i} height={[281,235,281]} sx={{backgroundImage:`url(${image})`,backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}}/>
									// 	</Box>
									// ))
									<Box width="100%">
										<Flex py={3} widht={"100%"} flexDirection="row" className="gallery-container">
											<GridLightBox sources={gallery} />
										</Flex>
									</Box>
									:null
								}
							</Flex>
						</Flex>
					</Flex>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Servicio;