import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box,
  Button
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Productos.module.css';

import Productos1 from "../../../assets/producto1.svg"
import Productos2 from "../../../assets/producto2.svg"
import Productos3 from "../../../assets/producto3.svg"
import Productos4 from "../../../assets/producto4.svg"

import Productosbg1 from "../../../assets/Productos/producto1.jpg"
import Productosbg2 from "../../../assets/Productos/producto2.jpg"
import Productosbg3 from "../../../assets/Productos/producto3.jpg"
import Productosbg4 from "../../../assets/Productos/producto4.jpg"

import arrowDown from "../../../assets/arrowDown.svg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Productos = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Productos",
		boxTitle1_1: "Productos para",
		boxTitle1_2: "Tratamiento de Agua",
		boxTitle2_1: "Productos para",
		boxTitle2_2: "Piscinas y Químicos",
		boxTitle3_1: "Productos para",
		boxTitle3_2: "Sistemas de Bombeo",
		boxTitle4_1: "Sistemas de",
		boxTitle4_2: "Agua Caliente",
		seeProducts: "Ver todos los Productos"
	 },
	 en: {
		title: "Products",
		boxTitle1_1: "Plumbing and Electricity",
		boxTitle1_2: "in Construction",
		boxTitle2_1: "Design, construction and",
		boxTitle2_2: "maintenance of",
		boxTitle3_1: "Maintenance and",
		boxTitle3_2: "Installations of",
		boxTitle4_1: "Sale of pumping equipment,",
		boxTitle4_2: "heaters and filtration",
		seeProducts: "See all the Products" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)

	return(
			<div className={styles.Container} id="Productos">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300}} transition={{ duration: 1 }} className={"MaxDimensions"} style={{position:"relative"}}>
								<Flex justifyContent={["flex-start","space-between","space-between"]} alignItems="center" mb={[50,70,90]}>
									<Text
										fontWeight="900" 
										textAlign="left" 
										fontSize={[45,65]} 
										color="#2E333E">{trans.title}</Text>
									<button className={styles.Button} onClick={() => history.push(`/${urlLang}/productos`)}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div className="hoverWhite">{trans.seeProducts}</div>
										</div>
									</button>
								</Flex>
								<Flex justifyContent="center" alignItems="center" flexDirection="column">
									<Flex flexDirection={["row","row","row"]} flexWrap="wrap" backgroundColor="#15203C" width="100%" height="100%" px={20} py={20}>
										<Flex flexWrap="wrap" width={["100%","100%","100%"]}>
											<Box height="270px" mb={[20,20,0]} mr={[0,"10px","10px"]} className="ZoomInContainer" width={["100%","calc(50% - 10px)","calc(25% - 15px)"]}>
													<Flex width="70%" flexDirection="column" maxHeight="150px" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" marginBottom="15px" alignItems="center" justifyContent="center">
															<Image src={Productos1}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle1_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle1_2}</Text>
														</Flex>
													</Flex>
												<Flex flexDirection="column"  backgroundColor="#373E50" className="ZoomIn" sx={{backgroundImage:`url(${Productosbg1})`,backgroundSize:"cover",cursor:"pointer"}}  alignItems="center" justifyContent="center"  >
												</Flex>
											</Box>
											<Box height="270px" mb={[20,20,0]} ml={[0,"10px","10px"]} mr={[0,0,"10px"]} className="ZoomInContainer" width={["100%","calc(50% - 10px)","calc(25% - 15px)"]}>
													<Flex width="70%" flexDirection="column" maxHeight="150px" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" marginBottom="15px" alignItems="center" justifyContent="center">
															<Image src={Productos2}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle2_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle2_2}</Text>
														</Flex>
													</Flex>
												<Flex flexDirection="column"   backgroundColor="#373E50" className="ZoomIn" sx={{backgroundImage:`url(${Productosbg2})`,backgroundSize:"cover",cursor:"pointer"}}  alignItems="center" justifyContent="center"  >
												</Flex>
											</Box>
											<Box height="270px" mb={[20,0,0]} mr={[0,"10px","10px"]} ml={[0,0,"10px"]} className="ZoomInContainer" width={["100%","calc(50% - 10px)","calc(25% - 15px)"]}>
													<Flex width="70%" flexDirection="column" maxHeight="150px" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" marginBottom="15px" alignItems="center" justifyContent="center">
															<Image src={Productos3}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle3_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle3_2}</Text>
														</Flex>
													</Flex>
												<Flex flexDirection="column"   backgroundColor="#373E50" className="ZoomIn" sx={{backgroundImage:`url(${Productosbg3})`,backgroundSize:"cover",cursor:"pointer"}}  alignItems="center" justifyContent="center"  >
												</Flex>
											</Box>
											<Box height="270px" mb={[0,0,0]} ml={[0,"10px","10px"]} className="ZoomInContainer" width={["100%","calc(50% - 10px)","calc(25% - 15px)"]}>
													<Flex width="70%" flexDirection="column" maxHeight="150px" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" marginBottom="15px" alignItems="center" justifyContent="center">
															<Image src={Productos4}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle4_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle4_2}</Text>
														</Flex>
													</Flex>
												<Flex flexDirection="column"  backgroundColor="#373E50" className="ZoomIn" sx={{backgroundImage:`url(${Productosbg4})`,backgroundSize:"cover",cursor:"pointer"}}  alignItems="center" justifyContent="center"  >
												</Flex>
											</Box>
										</Flex>
									</Flex>
									<Flex mt={[35]} flexDirection={["row","row","row"]} flexWrap="wrap" width="100%" height="100%" p={20}>
										<Flex flex="1" alignItems="center" justifyContent="center">
											<button className={styles.Button2} onClick={() => history.push(`/${urlLang}/productos`)}>
												<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
													<div className="hoverWhite">{trans.seeProducts}</div>
												</div>
											</button>
										</Flex>
									</Flex>
								</Flex>
							</motion.div>
					)}}
				</TrackVisibility>
			</div>
	);
}

export default Productos;