import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrowColor.svg'
import styles from './Servicios.module.css';
import servicios1 from "../../../assets/servicios1_color.svg"
import servicios2 from "../../../assets/servicios2_color.svg"
import servicios3 from "../../../assets/servicios3_color.svg"
import servicios4 from "../../../assets/servicios4_color.svg"
import servicios5 from "../../../assets/servicios5_color.svg"

import serviciosMobile1 from "../../../assets/Servicios/Servicio_1.jpg"
import serviciosIPad1 from "../../../assets/Servicios/Servicio_1.jpg"
import serviciosDesk1 from "../../../assets/Servicios/Servicio_1.jpg"

import serviciosMobile2 from "../../../assets/Servicios/Servicio_2.jpg"
import serviciosIPad2 from "../../../assets/Servicios/Servicio_2.jpg"
import serviciosDesk2 from "../../../assets/Servicios/Servicio_2.jpg"

import serviciosMobile3 from "../../../assets/Servicios/Servicio_3.jpg"
import serviciosIPad3 from "../../../assets/Servicios/Servicio_3.jpg"
import serviciosDesk3 from "../../../assets/Servicios/Servicio_3.jpg"

import serviciosMobile4 from "../../../assets/Servicios/Servicio_4.jpg"
import serviciosIPad4 from "../../../assets/Servicios/Servicio_4.jpg"
import serviciosDesk4 from "../../../assets/Servicios/Servicio_4.jpg"

import serviciosMobile5 from "../../../assets/Servicios/Servicio_5.jpg"
import serviciosIPad5 from "../../../assets/Servicios/Servicio_5.jpg"
import serviciosDesk5 from "../../../assets/Servicios/Servicio_5.jpg"

import Servicio from '../Servicio/Servicio'
import {FullscreenModal} from '../../../components/Modal/FullscreenModal'

import { motion } from "framer-motion"
import TrackVisibility from 'react-on-screen';
import LazyLoad from 'react-lazyload';

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Servicios",
		boxTitle1:"Plomería y Electricidad en Construcción",
		boxTitle1_1: "Plomería y Electricidad",
		boxTitle1_2: "en Construcción",
		
		boxTitle2: "Diseño, construcción y mantenimiento de piscinas, spa, fuentes etc.",
		boxTitle2_1: "Diseño, construcción y",
		boxTitle2_2: "mantenimiento de",
		boxTitle2_3: "piscinas, spa, fuentes etc.",
		
		boxTitle3: "Mantenimiento e Instalaciones de Plomería y Electricidad",
		boxTitle3_1: "Mantenimiento e Instalaciones",
		boxTitle3_2: "de Plomería y Electricidad",
		
		boxTitle4: "Venta de equipos de Bombeo, Calentadores y Filtrado para servicio de Piscinas",
		boxTitle4_1: "Venta de equipos de Bombeo,",
		boxTitle4_2: "Calentadores y Filtrado",
		boxTitle4_3: "para servicio de Piscinas",
		
		boxTitle5: "Distribución de túneles sanitizantes",
		boxTitle5_1: "Distribución de túneles",
		boxTitle5_2: "sanitizantes",

		seeMore: "Ver más"
	 },
	 en: {
		title: "Services",

		boxTitle1: "Plumbing and Electricity in Construction",
		boxTitle1_1: "Plumbing and Electricity",
		boxTitle1_2: "in Construction",
		
		boxTitle2: "Design, construction and maintenance of swimming pools, spas, fountains, etc.",
		boxTitle2_1: "Design, construction and",
		boxTitle2_2: "maintenance of",
		boxTitle2_3: "swimming pools, spas, fountains, etc.",
		
		boxTitle3: "Maintenance and Installations of Plumbing and Electricity",
		boxTitle3_1: "Maintenance and Installations",
		boxTitle3_2: "of Plumbing and Electricity",
		
		boxTitle4: "Sale of pumping equipment, heaters and filtration",
		boxTitle4_1: "Sale of pumping equipment,",
		boxTitle4_2: "heaters and filtration",
		boxTitle4_3: "for swimming pool service",
		
		boxTitle5: "Distribution of sanitation tunnels",
		boxTitle5_1: "Distribution of sanitation",
		boxTitle5_2: "tunnels",
		seeMore: "See more" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	

	const [ beenHere1, setBeenHere1] = useState(false)
	const [ beenHere2, setBeenHere2] = useState(false)
	const [ beenHere3, setBeenHere3] = useState(false)
	const [ beenHere4, setBeenHere4] = useState(false)
	const [ beenHere5, setBeenHere5] = useState(false)


	return(
		<div className={styles.Container} id="Servicios">
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				<Flex flexDirection={["column"]} >

					
						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere1(true)
								return(
									<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere1) ? 1 : 0, x: (isVisible || beenHere1) ? 0 : -1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[70,100,70]} sx={{order:["unset","unset",2]}}>
												<Flex justifyContent={["center","center","flex-end"]} mb={[25]}>
													<Image src={servicios1} />
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle1_1}</Text>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle1_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]}>
													<button className={styles.Button} id={0} onClick={() => history.push(`/${urlLang}/servicio/0`)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.seeMore}</div>
															<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} className={styles.servicesContainer} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={serviciosMobile1}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={serviciosIPad1}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={serviciosDesk1}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>
					
					
						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere2(true)
								return(
									<motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere2) ? 1 : 0, y: (isVisible || beenHere2) ? 0 : 1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[70,100,70]} >
												<Flex justifyContent={["center","center","flex-start"]} mb={[25]}>
													<Image src={servicios2} />
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle2_1}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle2_2}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle2_3}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]}>
													<button className={styles.Button} id={1} onClick={() => history.push(`/${urlLang}/servicio/1`)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.seeMore}</div>
															<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} className="servicesContainer" sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxR} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={serviciosMobile2}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={serviciosIPad2}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={serviciosDesk2}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

					
						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere3(true)
								return(
									<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere3) ? 1 : 0, x: (isVisible || beenHere3) ? 0 : -1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[70,100,70]} sx={{order:["unset","unset",2]}}>
												<Flex justifyContent={["center","center","flex-end"]} mb={[25]}>
													<Image src={servicios3} />
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle3_1}</Text>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle3_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]}>
													<button className={styles.Button} id={2} onClick={() => history.push(`/${urlLang}/servicio/2`)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.seeMore}</div>
															<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} className="servicesContainer" sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={serviciosMobile3}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={serviciosIPad3}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={serviciosDesk3}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

					
						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere4(true)
								return(
									<motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere4) ? 1 : 0, y: (isVisible || beenHere4) ? 0 : 1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[70,100,70]} >
												<Flex justifyContent={["center","center","flex-start"]} mb={[25]}>
													<Image src={servicios4} />
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle4_1}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle4_2}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle4_3}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]}>
													<button className={styles.Button} id={3} onClick={() => history.push(`/${urlLang}/servicio/3`)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.seeMore}</div>
															<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} className="servicesContainer" sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxR} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={serviciosMobile4}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={serviciosIPad4}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={serviciosDesk4}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

					
						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere5(true)
								return(
									<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere5) ? 1 : 0, x: (isVisible || beenHere5) ? 0 : -1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[70,100,70]} sx={{order:["unset","unset",2]}}>
												<Flex justifyContent={["center","center","flex-end"]} mb={[25]}>
													<Image src={servicios5} />
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle5_1}</Text>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle5_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]}>
													<button className={styles.Button} id={4} onClick={() => history.push(`/${urlLang}/servicio/4`)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.seeMore}</div>
															<img style={{height:"15px",marginLeft:"10px"}} src={send} alt=""/>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} className="servicesContainer" sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={serviciosMobile5}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={serviciosIPad5}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={serviciosDesk5}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

				</Flex>
			</div>
		</div>
	);
}

export default Servicios;