import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import Carousel from "../../../components/Carousel/Carousel" 
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrow.svg'
import styles from './Clientes.module.css';
import cliente from "../../../assets/cliente.svg"
import arrowRight from "../../../assets/arrowRight.svg" 
import arrowLeft from "../../../assets/arrowLeft.svg" 

import Clientes1 from "../../../assets/Clientes/Chileno.jpg"
import Clientes2 from "../../../assets/Clientes/Costa-Baja.jpg" 
import Clientes3 from "../../../assets/Clientes/Diamante.jpg" 
import Clientes4 from "../../../assets/Clientes/El-Dorado.jpg" 
import Clientes5 from "../../../assets/Clientes/GRAVI.jpg"
import Clientes6 from "../../../assets/Clientes/IDS.jpg"
import Clientes7 from "../../../assets/Clientes/Maravilla.jpg" 
import Clientes8 from "../../../assets/Clientes/Palmilla.jpg" 
import Clientes9 from "../../../assets/Clientes/Pedregal.jpg" 
import Clientes10 from "../../../assets/Clientes/ZYZ.jpg"    

import ClientesBg from "../../../assets/clientes.jpg"    

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Clientes = (props) => {
	const history = useHistory();
	const CarouselRef = useRef();
	let trans = new LocalizedStrings({
	 es:{
		title: "Clientes",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeMore: "Ver más"
	 },
	 en: {
		title: "Clients",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeMore: "See more" 
	 }
	});

	const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      arrows:false,
      slidesToScroll: 1,
    };

    const next = () => {
      CarouselRef.current.slickNext();
    }
    const previous = () =>  {
      CarouselRef.current.slickPrev();
    }

	const clients = [
		{
			img:cliente,
			client:"Nombre cliente1",
			person:"Nombre persona / puesto1",
			desc:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr,  Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,"
		},
		{
			img:cliente,
			client:"Nombre cliente2",
			person:"Nombre persona / puesto2",
			desc:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr,  Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,",
		},
		{
			img:cliente,
			client:"Nombre cliente3",
			person:"Nombre persona / puesto3",
			desc:"Lorem ipsum dolor sit amet, consetetur sadipscing elitr,  Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,"
		}
	]

	const bgImageStyles = {
		backgroundPosition: 'center',
		backgroundSize: 'cover'
	}

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)

	return(
			<div className={styles.Container} id="Clientes">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300}} transition={{ duration: 1 }} className={"MaxDimensions"} style={{position:"relative"}}>
								<Flex flexDirection={["row"]}  flexWrap="wrap">
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes1})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={["7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes2})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} ml={["7.5px"]} mr={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes3})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={["7.5px"]} ml={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes4})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} ml={["7.5px"]} mr={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes5})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={["7.5px","7.5px",0]} ml={[0,0,"7.5px"]}  >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes6})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={[0,0,"7.5px"]} ml={["7.5px","7.5px",0]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes7})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={["7.5px"]} ml={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes8})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} ml={["7.5px"]} mr={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes9})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} mr={["7.5px"]} ml={[0,0,"7.5px"]} >
										
									</Box>
									<Box width={["calc(50% - 7.5px)","calc(50% - 7.5px)","calc(20% - 12px)" ]} sx={Object.assign({backgroundImage:`url(${Clientes10})`}, bgImageStyles)} mb="14px" height={["166px","257px", "202px" ]} ml={["7.5px"]} >
										
									</Box>
								</Flex>

								<Flex width="100%" sx={{
									backgroundImage: `url(${ClientesBg})`,
									backgroundSize:"cover",
									position:"relative",
									backgroundAttachment: "fixed",
					    			backgroundPosition: "center",
					    			backgroundRepeat: "no-repeat"}} 
					    			height="510px" px={"19px"} mt={["60px"]}  pt={["60px"]}>
									<Image src={arrowRight} onClick={() => next()} className={styles.AbsoluteArrow+" getBig" } sx={{right:["5px",100,150]}}/>
									<Image src={arrowLeft} onClick={() => previous()} className={styles.AbsoluteArrow+" getBig" } sx={{left:["5px",100,150]}}/>
									<Slider ref={CarouselRef} {...settings} className="carousel">
									{
										clients.map((cli,i) => (
											<Flex flexDirection="column" key={"flex"+i} className={styles.Grab} >
												<Image src={cli.img} height={"100%"}
													width={"100%"}
													sx={{
													}}
													maxHeight={[150]}  className="object-cover" key={"img"+i}/>
												<Box mt="30px" key={"box1"+i}>
													<Box height="fit-content" key={"box2"+i} maxWidth={400} margin="auto">
														<Text key={"text1"+i}  color="white" fontSize={[20]} fontWeight="700">{cli.client}</Text>
														<Text key={"text2"+i} color="white" fontSize={[20]} fontWeight="700">{cli.person}</Text>
													</Box>
													<Box key={"box3"+i} mt="10px" maxWidth={400} margin="auto">
														<Text key={"text3"+i}  color="white" fontSize={[18]} lineHeight="28px" >
															{cli.desc}
														</Text>
													</Box>
												</Box>
											</Flex>
										))
									}
									</Slider>
								</Flex>
							</motion.div>
					)}}
				</TrackVisibility>
			</div>
	);
}

export default Clientes;