import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrow.svg'
import styles from './Productos.module.css';
import servicios1 from "../../../assets/servicios1_color.svg"
import servicios2 from "../../../assets/servicios2_color.svg"
import servicios3 from "../../../assets/servicios3_color.svg"
import servicios4 from "../../../assets/servicios4_color.svg"
import servicios5 from "../../../assets/servicios5_color.svg"

import serviciosMobile1 from "../../../assets/servicios_serviciosMobile1.png"
import serviciosIPad1 from "../../../assets/servicios_serviciosIPad1.png"
import serviciosDesk1 from "../../../assets/servicios_serviciosDesk1.png"

import serviciosMobile2 from "../../../assets/servicios_serviciosMobile2.png"
import serviciosIPad2 from "../../../assets/servicios_serviciosIPad2.png"
import serviciosDesk2 from "../../../assets/servicios_serviciosDesk2.png"

import serviciosMobile3 from "../../../assets/servicios_serviciosMobile3.png"
import serviciosIPad3 from "../../../assets/servicios_serviciosIPad3.png"
import serviciosDesk3 from "../../../assets/servicios_serviciosDesk3.png"

import serviciosMobile4 from "../../../assets/servicios_serviciosMobile4.png"
import serviciosIPad4 from "../../../assets/servicios_serviciosIPad4.png"
import serviciosDesk4 from "../../../assets/servicios_serviciosDesk4.png"

import serviciosMobile5 from "../../../assets/servicios_serviciosMobile5.png"
import serviciosIPad5 from "../../../assets/servicios_serviciosIPad5.png"
import serviciosDesk5 from "../../../assets/servicios_serviciosDesk5.png"

import Pdf from "../../../assets/sample.pdf"

import Pdf1 from "../../../assets/Productos/pdf1.pdf"
import Pdf2 from "../../../assets/Productos/pdf2.pdf"
import Pdf3 from "../../../assets/Productos/pdf3.pdf"

import productos1 from "../../../assets/Productos/productos1.jpg"
import productos2 from "../../../assets/Productos/productos2.jpg"
import productos3 from "../../../assets/Productos/productos3.jpg"
import productos4 from "../../../assets/Productos/productos4.jpg"

import icon1 from "../../../assets/Productos/1.svg"
import icon2 from "../../../assets/Productos/2.svg"
import icon3 from "../../../assets/Productos/3.svg"
import icon4 from "../../../assets/Productos/4.svg"

import productosbg1 from "../../../assets/Productos/1.png"
import productosbg2 from "../../../assets/Productos/2.png"
import productosbg3 from "../../../assets/Productos/3.png"
import productosbg4 from "../../../assets/Productos/4.png"

import { motion } from "framer-motion"
import LazyLoad from 'react-lazyload';
import TrackVisibility from 'react-on-screen';

const Productos = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Productos",
		boxTitle1_1: "Productos para ",
		boxTitle1_2: "Tratamiento de Agua",
		boxTitle2_1: "Productos para Piscinas ",
		boxTitle2_2: "y Químicos",
		boxTitle3_1: "Productos para Sistemas ",
		boxTitle3_2: "de Bombeo",
		boxTitle4_1: "Sistemas de Agua ",
		boxTitle4_2: "Caliente",
		download: "Descargar catálogo"
	 },
	 en: {
		title: "Products",
		boxTitle1_1: "Products for",
		boxTitle1_2: "Water treatment",
		boxTitle2_1: "Pool Products",
		boxTitle2_2: "yand Chemicals",
		boxTitle3_1: "Systems Products",
		boxTitle3_2: "pumping",
		boxTitle4_1: "Water Systems",
		boxTitle4_2: "Hot",
		download: "Downlaod catalogue" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const openPDF = (id) => {
		switch (id) {
			case 1:
				 window.open(Pdf1, "_blank",);
			case 2:
				 window.open(Pdf2, "_blank",);
			case 3:
				 window.open(Pdf3, "_blank",);
			case 4:
				 window.open(Pdf, "_blank",);
			default:
				 window.open(Pdf, "_blank",)
		}
	}

	const [ beenHere1, setBeenHere1] = useState(false)
	const [ beenHere2, setBeenHere2] = useState(false)
	const [ beenHere3, setBeenHere3] = useState(false)
	const [ beenHere4, setBeenHere4] = useState(false)


	return(
		<div className={styles.Container} id="Productos">
			<div className={"MaxDimensions"} style={{position:"relative"}}>
				<Flex flexDirection={["column"]} >

					<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere1(true)
								return(
									<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere1) ? 1 : 0, x: (isVisible || beenHere1) ? 0 : -1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[50]} sx={{order:["unset","unset",2]}}>
												<Flex justifyContent={["center","center","flex-end"]} mb={[25]}>
													<Image width="60px" height="60px" sx={{objetFit:"cover"}} src={icon1} />
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle1_1}</Text>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle1_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]}>
													<button className={styles.Button} onClick={() => openPDF(1)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.download}</div>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} width="100%" src={productosbg1}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>
				
					<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere2(true)
								return(
									<motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere2) ? 1 : 0, y: (isVisible || beenHere2) ? 0 : 1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[50]} >
												<Flex justifyContent={["center","center","flex-start"]} mb={[25]}>
													<Image width="60px" height="60px" sx={{objetFit:"cover"}} src={icon2} />
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle2_1}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle2_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]}>
													<button className={styles.Button} onClick={() => openPDF(2)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.download}</div>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxR} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} width="100%" src={productosbg2} />
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

					<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere3(true)
								return(
									<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere3) ? 1 : 0, x: (isVisible || beenHere3) ? 0 : -1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[50]} sx={{order:["unset","unset",2]}}>
												<Flex justifyContent={["center","center","flex-end"]} mb={[25]}>
													<Image width="60px" height="60px" sx={{objetFit:"cover"}} src={icon3} />
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle3_1}</Text>
													<Text textAlign={["center","center","right"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle3_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-end"]}>
													<button className={styles.Button} onClick={() => openPDF(3)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.download}</div>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} width="100%" src={productosbg3}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

					<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere4(true)
								return(
									<motion.div initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere4) ? 1 : 0, y: (isVisible || beenHere4) ? 0 : 1000 }} transition={{ duration: 1 }}>
										<Flex flexDirection={["column","column","row"]} mb={[70,100,70]} alignItems={["unset","unset","center"]}>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}  mb={[50]} >
												<Flex justifyContent={["center","center","flex-start"]} mb={[25]}>
													<Image width="60px" height="60px" sx={{objetFit:"cover"}} src={icon4} />
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]} flexDirection={["column"]} mb={[25]}>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle4_1}</Text>
													<Text textAlign={["center","center","left"]} fontSize={[28,45]} fontWeight="700" color="#15203C">{trans.boxTitle4_2}</Text>
												</Flex>
												<Flex justifyContent={["center","center","flex-start"]}>
													<button style={{display:"none"}}className={styles.Button} onClick={() => openPDF(4)}>
														<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
															<div className="hoverWhite">{trans.download}</div>
														</div>
													</button>
												</Flex>
											</Flex>
											<Flex flexDirection={["column"]} width={["100%","100%","50%"]}>
												<Flex maxHeight={['450px', '600px', 'auto']} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
													<Box className={styles.AbsoluteBoxR} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} width="100%" src={productosbg4}/>
												</Flex>
											</Flex>
										</Flex>
									</motion.div>
								)}}
						</TrackVisibility>

				</Flex>
			</div>
		</div>
	);
}

export default Productos;