import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../../assets/sendArrow.svg'
import styles from './Proyectos.module.css';

import Proyecto from '../Proyecto/Proyecto'

import proyectosMobile1 from "../../../assets/proyectosMobile1.png"
import proyectosMobile2 from "../../../assets/proyectosMobile2.png"
import proyectosMobile3 from "../../../assets/proyectosMobile3.png"
import proyectosMobile4 from "../../../assets/proyectosMobile4.png"

import proyectosIPad1 from "../../../assets/proyectosIPad1.png"
import proyectosIPad2 from "../../../assets/proyectosIPad2.png"
import proyectosIPad3 from "../../../assets/proyectosIPad3.png"
import proyectosIPad4 from "../../../assets/proyectosIPad4.png"

import proyectosDesk1 from "../../../assets/proyectosDesk1.png"
import proyectosDesk2 from "../../../assets/proyectosDesk2.png"
import proyectosDesk3 from "../../../assets/proyectosDesk3.png"
import proyectosDesk4 from "../../../assets/proyectosDesk4.png"
import proyects from "../../../misc/proyectos"

import { motion } from "framer-motion"
import TrackVisibility from 'react-on-screen';

const Proyectos = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Proyectos",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeEveryProject: "Ver todos los proyectos"
	 },
	 en: {
		title: "Pojects",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod",
		seeEveryProject: "See all projects" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [displayProyect, setDisplayProyect] = useState(false)
	const [currentProyect, setCurrentProyect] = useState(null)

	const showProyect = (e) => {
		setCurrentProyect(proyects[e.currentTarget.id])
		setDisplayProyect(true)
	}


	return(
		<div className={styles.Container} id="Proyectos">
			<TrackVisibility partialVisibility  once>		
			 	{({ isVisible }) => (
					<motion.div className={"MaxDimensions"} style={{position:"relative"}}  initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible ) ? 1 : 0, x: (isVisible ) ? 0 : -1000 }} transition={{ duration: 1 }}>
						<Flex flexDirection={["column"]} >
						<Flex flexDirection={["column"]}>
							<Flex flexDirection={["column","row"]} justifyContent={["space-between"]} flexWrap="wrap">
								{proyects.map((proyect, index)=>{

									return(
											<Box className="ZoomInContainer" height={[281,235,281]} id={index} onClick={() => history.push(`/${urlLang}/proyecto/${proyect.id}`)} mb={[87, 87, 92]} mt={[21, 21, 25]} mx={[0,9,11]} width={[1, "calc(50% - 33px)", "calc(33.33% - 22px)"]} sx={{position:"relative", cursor:"pointer"}}>
												<Flex flexDirection="column" justifyContent="center" alignItems="center" bg="white" width={[280,242]} height={[97,81,97]} sx={{zIndex:1}} className={styles.AbsoluteTextBox+" ZoomSibling"}>
													<Text color="#15203C" fontWeight="700" fontSize={["20px","18px","20px"]}>{proyect.client}</Text>
													<Text color="#15203C" fontSize={["18px","16px","18px"]}>{proyect.type}</Text>
												</Flex>
												<Box className="ZoomIn" sx={{backgroundImage:`url(${proyect.image})`}}>
												</Box>
											</Box>
								)})}
							</Flex>
						</Flex>
						</Flex>
					</motion.div>
					)}
			</TrackVisibility>
		</div>
	);
}

export default Proyectos;