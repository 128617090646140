import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import theme from '../../theme';
import Loader from '.././Loader/Loader';
import {
    Flex,
    Box,
    Card,
    Image,
    Heading,
    Text,
    Link
} from 'rebass';

export default function Grid(props) {
	
	const [completeColumns, setCompleteColumns] = useState([]);
	
	const config = props.config;
	const gridStyles = {
		display: 'grid',
		columnGap: config.columnGutter,
		gridTemplateColumns: [
			'repeat(auto-fit, minmax(' + ((1/config.columns[0])*100)*.9 + '%, 1fr))',
			'repeat(auto-fit, minmax(' + ((1/config.columns[1])*100)*.9 + '%, 1fr))',
			'repeat(auto-fit, minmax(' + ((1/config.columns[2])*100)*.9 + '%, 1fr))',
		]
	};
	let aspectRatio = config.aspectRatio.toString();
	aspectRatio = aspectRatio.split(':');
	const itemStyles = {
		marginBottom: config.rowGutter,
		position: "relative",
		'&:after': {
			content: "''",
			display: "block",
			height: 0,
			width: 0,
			paddingBottom: (parseInt(aspectRatio[1])/parseInt(aspectRatio[0]))*100 + "%" 
		}
	};

	const itemContainerStyles = { position: "absolute" }

	const sxGrid = config.customGridStyles ? {...gridStyles, ...config.customGridStyles} : gridStyles;
	const sxItem = config.customItemStyles ? {...itemStyles, ...config.customItemStyles} : itemStyles;
	const sxItemContainer = config.customItemContainerStyles ? {...itemContainerStyles, ...config.customItemContainerStyles} : itemContainerStyles;

	useEffect(() => {
		for(let i = 0; i < config.columns.length; i++) {
			if(config.columns[i] > props.elements.length) {
				if(window.matchMedia( "(max-width: "+ theme.breakpoints[i] +")").matches || config.columns.length == i + 1) {
					let items = [];
					for (var j = 0; j < config.columns[i] - props.elements.length; j++) {
						items.push(<Box key={"extra"+j} className="item" sx={{position:"relative"}} />);
					}
					setCompleteColumns(items);
					break;
				}
			}	
		}
	}, [props.elements]);

	return (
		<Box height="100%" sx={sxGrid}>
		{
			props.elements ? props.elements.map((element, i) => {
				return (
					<Box key={i} className="item" sx={sxItem}>
						<Box height="100%" width="100%" sx={sxItemContainer}>
							{React.cloneElement(props.children, {
								key: "child" + i,
								element: element
							})}
						</Box>
					</Box>
				)
			})
			: <Loader />
		}
		{
			completeColumns.length ?
				completeColumns
			: null
		}
		</Box>
	)

}
