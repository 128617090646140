import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box,
  Button
} from 'rebass';
import send from '../../../assets/sendArrow2.svg'
import styles from './Servicios.module.css';
import servicios1 from "../../../assets/servicios1.svg"
import servicios2 from "../../../assets/servicios2.svg"
import servicios3 from "../../../assets/servicios3.svg"
import servicios4 from "../../../assets/servicios4.svg"
import servicios5 from "../../../assets/servicios5.svg"

import serviciobg1 from "../../../assets/Servicios/servicio1.jpg"
import serviciobg2 from "../../../assets/Servicios/servicio2.jpg"
import serviciobg3 from "../../../assets/Servicios/servicio3.jpg"
import serviciobg4 from "../../../assets/Servicios/servicio4.jpg"
import serviciobg5 from "../../../assets/Servicios/servicio5.jpg"

import arrowDown from "../../../assets/arrowDown.svg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Servicios = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Servicios",
		boxTitle1_1: "Plomería y Electricidad",
		boxTitle1_2: "en Construcción",
		boxTitle2_1: "Diseño, construcción y",
		boxTitle2_2: "mantenimiento de",
		boxTitle2_3: "piscinas, spa, fuentes etc.",
		boxTitle3_1: "Mantenimiento e",
		boxTitle3_2: "Instalaciones de",
		boxTitle3_3: "Plomería y Electricidad",
		boxTitle4_1: "Venta de equipos de Bombeo,",
		boxTitle4_2: "Calentadores y Filtrado",
		boxTitle4_3: "para servicio de Piscinas",
		boxTitle5_1: "Distribución de túneles",
		boxTitle5_2: "sanitizantes",
		seeMore: "Ver todos los Servicios"
	 },
	 en: {
		title: "Services",
		boxTitle1_1: "Plumbing and Electricity",
		boxTitle1_2: "in Construction",
		boxTitle2_1: "Design, construction and",
		boxTitle2_2: "maintenance of",
		boxTitle2_3: "swimming pools, spas, fountains, etc.",
		boxTitle3_1: "Maintenance and",
		boxTitle3_2: "Installations of",
		boxTitle3_3: "Plumbing and Electricity",
		boxTitle4_1: "Sale of pumping equipment,",
		boxTitle4_2: "heaters and filtration",
		boxTitle4_3: "for swimming pool service",
		boxTitle5_1: "Distribution of sanitation",
		boxTitle5_2: "tunnels",
		seeMore: "See all the Services" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)


	return(
			<div className={styles.Container} id="Servicios">
				<TrackVisibility partialVisibility>		
					{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
						<React.Fragment>
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000}} transition={{ duration: 1 }} className={"MaxDimensions"} style={{position:"relative"}}>
								<Flex justifyContent={["flex-start","space-between","space-between"]} alignItems="center" mb={[50,90,90]}>
									<Text
										fontWeight="900" 
										textAlign="left" 
										fontSize={[45,65]} 
										color="#2E333E">{trans.title}</Text>
									<button className={styles.Button} onClick={() => history.push(`/${urlLang}/servicios`)}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div className="hoverWhite">{trans.seeMore}</div>
										</div>
									</button>
								</Flex>
							</motion.div>
							<motion.div initial={{ opacity: 0, y: 300 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, y: (isVisible || beenHere) ? 0 : 300}} transition={{ duration: 1 }} className={"MaxDimensions"} style={{position:"relative"}}>							
								<Flex justifyContent="center" alignItems="center">
									<Flex flexDirection={["row","row","row"]} flexWrap="wrap" backgroundColor="#15203C" width="100%" height="100%" p={20}>
										<Box mb={["20px","20px",0]} width={["100%","100%","calc(33.33% - 13.33px)"]} mr={[0,0,"10px"]} backgroundColor="#373E50" height={["280px","280px","580px"]} className="ZoomInContainer">
												<Flex flexDirection="column" maxHeight="150px" width="90%" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
													<Flex flex="1" alignItems="center" justifyContent="center" pb={18}>
														<Image src={servicios1}/>
													</Flex>
													<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
														<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle1_1}</Text>
														<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle1_2}</Text>
													</Flex>
												</Flex>
											<Flex onClick={() => history.push(`/${urlLang}/servicio/0`)} className="ZoomIn" sx={{backgroundImage:`url(${serviciobg1})`,backgroundSize:"cover",cursor:"pointer"}} flexDirection="column" alignItems="center" justifyContent="center"  >
											</Flex>
										</Box>
										<Flex flexWrap="wrap" flexDirection={["row","row","column"]} width={["100%","100%","calc(33.33% - 13.33px)"]} mr={[0,0,"10px"]} ml={[0,0,"10px"]}>
											<Box mb="20px" mr={[0,"10px",0]} backgroundColor="#373E50" width={["100%","calc(50% - 10px)","100%"]}  height="280px" className="ZoomInContainer">
													<Flex flexDirection="column" maxHeight="150px" width="90%" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" alignItems="center" justifyContent="center" pb={18}>
															<Image src={servicios2}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle2_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle2_2}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle2_3}</Text>
														</Flex>
													</Flex>
												<Flex onClick={() => history.push(`/${urlLang}/servicio/1`)} className="ZoomIn" sx={{backgroundImage:`url(${serviciobg2})`,backgroundSize:"cover",cursor:"pointer"}} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
											<Box className="ZoomInContainer" mb={["20px","20px",0]} ml={[0,"10px",0]} backgroundColor="#373E50" width={["100%","calc(50% - 10px)","100%"]}  height="280px">
													<Flex flexDirection="column" maxHeight="150px" width="90%" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" alignItems="center" justifyContent="center" pb={18}>
															<Image src={servicios3}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle3_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle3_2}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle3_3}</Text>
														</Flex>
													</Flex>
												<Flex onClick={() => history.push(`/${urlLang}/servicio/2`)} className="ZoomIn" sx={{backgroundImage:`url(${serviciobg3})`,backgroundSize:"cover",cursor:"pointer"}} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
										</Flex>

										<Flex flexWrap="wrap" flexDirection={["row","row","column"]} width={["100%","100%","calc(33.33% - 13.33px)"]} ml={[0,0,"10px"]}>
											<Box className="ZoomInContainer" mb="20px" mr={[0,"10px",0]} backgroundColor="#373E50" width={["100%","calc(50% - 10px)","100%"]}  height="280px">
													<Flex flexDirection="column" maxHeight="150px" width="90%" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" alignItems="center" justifyContent="center" pb={18}>
															<Image src={servicios4}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle4_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle4_2}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle4_3}</Text>
														</Flex>
													</Flex>
												<Flex onClick={() => history.push(`/${urlLang}/servicio/3`)} className="ZoomIn" sx={{backgroundImage:`url(${serviciobg4})`,backgroundSize:"cover",cursor:"pointer"}} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>
											<Box className="ZoomInContainer" mb={["20px","20px",0]} ml={[0,"10px",0]} backgroundColor="#373E50" width={["100%","calc(50% - 10px)","100%"]}  height="280px">
													<Flex flexDirection="column" maxHeight="150px" width="90%" className="ZoomSibling" sx={{position:"absolute",top:"50%",left:"50%",transform:"translate(-50%,-50%)",zIndex:1}}>
														<Flex flex="1" alignItems="center" justifyContent="center" pb={18}>
															<Image src={servicios5}/>
														</Flex>
														<Flex flexDirection="column" flex="1" alignItems="center" justifyContent="center">
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle5_1}</Text>
															<Text color="white" fontSize={[18]} fontWeight="700">{trans.boxTitle5_2}</Text>
														</Flex>
													</Flex>
												<Flex onClick={() => history.push(`/${urlLang}/servicio/4`)} className="ZoomIn" sx={{backgroundImage:`url(${serviciobg5})`,backgroundSize:"cover",cursor:"pointer"}} flexDirection="column" alignItems="center" justifyContent="center" >
												</Flex>
											</Box>

										</Flex>
									</Flex>
								</Flex>
								<Flex justifyContent="center" alignItems="center" mt={[35,0,0]}>
									<button className={styles.Button2} onClick={() => history.push(`/${urlLang}/servicios`)}>
										<div style={{display:"flex", width:"100%",height:"100%",justifyContent:"center",alignItems:"center"}}>
											<div className="hoverWhite">{trans.seeMore}</div>
										</div>
									</button>
								</Flex>
							</motion.div>
						</React.Fragment>
					)}}	
				</TrackVisibility>
			</div>
	);
}

export default Servicios;

