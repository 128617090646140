import React from 'react';

import sideBarLines from '../../../../assets/sideBarLines.svg'
import styles from './DrawerToggle.module.css';

const drawerToggle = (props) => (
	<div className={styles.DrawerToggle} onClick={props.clicked}>
		<div className={props.atTop ? styles.Text : styles.Text2}>MENU</div>
		<img className={props.atTop ? "" : styles.Image2} src={sideBarLines} alt=""/>
	</div>
);

export default drawerToggle;