import React, { useRef,useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import send from '../../assets/sendArrow.svg'
import styles from './Servicio.module.css';
import Layout from '../../components/Layout/Layout';
import ServicioInfo from '../../sections/Servicios/Servicio/Servicio'
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';


import services from "../../misc/servicios"

import { Fade } from "react-awesome-reveal"

const Servicio = (props) => {
	const ContactoRef = useRef(null)
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Servicios",
	 },
	 en: {
		title: "Services",
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);


	const [currentService, setCurrentService] = useState(null)
	const servicesList = services()
	console.log(servicesList)
	useEffect(() => {
		setCurrentService(servicesList[props.match.params.id])
	},[])

	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70)  ,behavior: 'smooth',})
	}



	return(
		<Fade delay={200}>
			<Layout scrollTo={{
				Contacto:toBottomContact,
			}}>
				<div className={styles.Container} id="Servicios">
					<ServicioInfo service={currentService}/>
					<div ref={ContactoRef}>
						<Contacto />
					</div>
					<Footer />
				</div>
			</Layout>
		</Fade>
	);
}

export default Servicio;