const checkValidity = (value, rules, form) => {
	let isValid = true;
	if(!rules){
		return true;
	}
	if(rules.required){
		isValid = value.trim() !== '' && isValid;
	}
	if(rules.minLength){
		isValid = value.length >= rules.minLength && isValid;
	}
	if(rules.maxLength){
		isValid = value.length <= rules.maxLength && isValid;
	}
	if(rules.isEmail){
		const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		isValid = pattern.test(value) && isValid;
	}
	if(rules.passwordConfirmation){
		isValid = value === form.password.value && isValid;
	}
	if(rules.emailConfirmation){
		isValid = value === form.email.value && isValid;
	}
	return isValid;
}

const errorsArray = (value, rules, form) => {
		let errors = [];
		if(!rules){
			return errors;
		}

		if(rules.required){
			if(! (value.trim() !== '')){
				errors.push(' el campo es requerido');
			}
			else if(errors.includes(' el campo es requerido')){
				errors.splice(errors.indexOf(' el campo es requerido'),1);
			}
		}
		if(rules.minLength){
			if(!(value.length >= rules.minLength)){
				errors.push(' minimo '+rules.minLength+' caracteres');
			}else if(errors.includes(' minimo '+rules.minLength+' caracteres')){
				errors.splice(errors.indexOf(' minimo '+rules.minLength+' caracteres'),1);
			}
		}
		if(rules.maxLength){
			if(!(value.length <= rules.maxLength)){
				errors.push(' maximo '+rules.minLength+' caracteres');
			}else if(errors.includes(' maximo '+rules.minLength+' caracteres')){
				errors.splice(errors.indexOf(' maximo '+rules.minLength+' caracteres'),1);
			}
		}
		if(rules.passwordConfirmation){
			if(!(value === form.password.value)){
				errors.push(' las contraseñas no coincide');
			}else if(errors.includes(' las contraseñas no coincide')){
				errors.splice(errors.indexOf(' las contraseñas no coincide'),1);
			}
		}
		if(rules.emailConfirmation){
			if(!(value === form.email.value)){
				errors.push(' los emails no coinciden');
			}
			else if(errors.includes(' los emails no coinciden')){
				errors.splice(errors.indexOf(' los emails no coinciden'),1);
			}
		}
		if(rules.isEmail){
			const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(!(pattern.test(value))){
            	errors.push(' formato de email incorrecto');
            }
            else if(errors.includes(' formato de email incorrecto')){
				errors.splice(errors.indexOf(' formato de email incorrecto'),1);
			}
		}
		return errors;
	}

export const inputChangeHandler = (event, inputIdentifier, form, setCallbackForm, setCallbackFormIsValid) => {
		const updatedForm = {
			...form
		}
		const updatedFormElement = {
			...form[inputIdentifier]
		}

		updatedFormElement.value = event.target.value;
		updatedFormElement.touched = true;
		updatedFormElement.valid = checkValidity(updatedFormElement.value, updatedFormElement.validation,form);
		updatedFormElement.errorType = errorsArray(updatedFormElement.value, updatedFormElement.validation,form);
		updatedForm[inputIdentifier] = updatedFormElement;

		let formIsValid = true;
		for(let inputIdentifier in updatedForm){
			formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
		}

		setCallbackFormIsValid(formIsValid);
		setCallbackForm(updatedForm);
	};