import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';

import styles from './Historia.module.css';
import historiaMobile1 from "../../../assets/nosotros_historiaMobile1.png"
import historiaIPad1 from "../../../assets/nosotros_historiaIPad1.png"
import historiaDesk1 from "../../../assets/nosotros_historiaDesk1.png"
import Nosotros21 from "../../../assets/nosotros_historiaMobile2.png"
import historiaIPad2 from "../../../assets/nosotros_historiaIPad2.png"
import historiaDesk2 from "../../../assets/nosotros_historiaDesk2.png"
import historiaMobile3 from "../../../assets/nosotros_historiaMobile3.png"
import historiaIPad3 from "../../../assets/nosotros_historiaIPad3.png"
import historiaDesk3 from "../../../assets/nosotros_historiaDesk3.png"
import historiaMobile4 from "../../../assets/nosotros_historiaMobile4.png"
import historiaIPad4 from "../../../assets/nosotros_historiaIPad4.png"
import historiaDesk4 from "../../../assets/nosotros_historiaDesk4.png"
import coverDesk2 from "../../../assets/nosotros_coverDesk2.png"   


//Nosotros   
import Nosotros2 from "../../../assets/Nosotros/Nosotros2.jpg"
import Nosotros3 from "../../../assets/Nosotros/Nostros3.jpg"
import Nosotros4 from "../../../assets/Nosotros/Nostros4.jpg"
import Nosotros5 from "../../../assets/Nosotros/Nostros5.jpg"
import Nosotros6 from "../../../assets/Nosotros/Nosotros6.jpg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"
import LazyLoad from 'react-lazyload';

const Historia = (props) => {
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title: "Historia",
		subtitle: "Idea Los Cabos fue fundada por el Sr. Bladimir Angel T. en el año 2004.",
		subtitle1: " Desde su llegada a Cabo San Lucas en 1997 en Sr. Bladimir se involucró en trabajos de electricidad y plomería, enfocándose en entender perfectamente todos los procesos, apasionado por aprender más y lograr mejores resultados.",
		subtitle2: "Gracias a su gran calidad de trabajo, muchos clientes empezaron a buscarlo directamente. En 2004 Idea Los Cabos inicia operaciones con tan solo 4 empleados, pero con una visión clara para posicionarse como los líderes en servicios de plomería y electricidad.",
		subtitle3: "Debido a la alta demanda y excelentes resultados con sus clientes, Idea Los Cabos incorpora en 2010 servicios de construcción y mantenimiento de todo tipo de albercas, así como el suministro de equipos y accesorios.",
		subtitle4: "Idea Los Cabos se posiciona actualmente como una empresa líder en su ramo, con más de 16 años de experiencia y con un equipo de más de 90 personas, colaborando con arquitectos y constructoras reconocidas de la región.",
		subtitle5: "Misión",
		subtitle6: "Generar valor proporcionando servicios de plomería , electricidad y construcción de albercas a inversionistas privados y administraciones publicas nacionales y extranjeras, mediante procesos eficaces y eficientes que sean referente en la industria. Utilizar el valor creado para cumplir los objetivos de los accionistas la superación integral de nuestro equipo humano y colaborar con el bienestar de la comunidad.",
		subtitle7: "Visión",
		subtitle8: "Ser una empresa líder en plomería, construcción y mantenimiento de cuerpos de agua por la preferencia de los clientes y los sistemas de trabajo con que opera, que le permitan un crecimiento sostenido con la más alta rentabilidad en su giro, cumpliendo estrictamente sus compromisos con los clientes y con la sociedad.",
		subtitle9: "Valores",
		valor1:"Crecimiento",
		valor2:"Rentabilidad",
		valor3:"Trabajo en equipo",
		valor4:"Integridad",
		valor5:"Lealtad",
		valor6:"Austeridad",
		valor7:"Confianza",
		valor8:"Calidad",
		valor9:"Iniciativa",

		values: ["Crecimiento", "Trabajo en equipo", "Integridad", "Lealtad", "Austeridad", "Confianza", "Calidad", "Iniciativa", "Aprendizaje"],

		seeMore: "Ver más"
	 },
	 en: {
		title: "About us",
		subtitle: "Idea Los Cabos fue fundada por el Sr. Bladimir Angel T. en el año 2004.",
		subtitle1: " Desde su llegada a Cabo San Lucas en 1997 en Sr. Bladimir se involucró en trabajos de electricidad y plomería, enfocándose en entender perfectamente todos los procesos, apasionado por aprender más y lograr mejores resultados.",
		subtitle2: "Gracias a su gran calidad de trabajo, muchos clientes empezaron a buscarlo directamente. En 2004 Idea Los Cabos inicia operaciones con tan solo 4 empleados, pero con una visión clara para posicionarse como los líderes en servicios de plomería y electricidad.",
		subtitle3: "Debido a la alta demanda y excelentes resultados con sus clientes, Idea Los Cabos incorpora en 2010 servicios de construcción y mantenimiento de todo tipo de albercas, así como el suministro de equipos y accesorios.",
		subtitle4: "Idea Los Cabos se posiciona actualmente como una empresa líder en su ramo, con más de 16 años de experiencia y con un equipo de más de 90 personas, colaborando con arquitectos y constructoras reconocidas de la región.",
		subtitle5: "Misión:",
		subtitle6: "Generar valor proporcionando servicios de plomería , electricidad y construcción de albercas a inversionistas privados y administraciones publicas nacionales y extranjeras, mediante procesos eficaces y eficientes que sean referente en la industria. Utilizar el valor creado para cumplir los objetivos de los accionistas la superación integral de nuestro equipo humano y colaborar con el bienestar de la comunidad.",
		subtitle7: "Visión:",
		subtitle8: "Ser una empresa líder en plomería, construcción y mantenimiento de cuerpos de agua por la preferencia de los clientes y los sistemas de trabajo con que opera, que le permitan un crecimiento sostenido con la más alta rentabilidad en su giro, cumpliendo estrictamente sus compromisos con los clientes y con la sociedad.",
		subtitle9: "Valores",
		valor1:"Crecimiento",
		valor2:"Rentabilidad",
		valor3:"Trabajo en equipo",
		valor4:"Integridad",
		valor5:"Lealtad",
		valor6:"Austeridad",
		valor7:"Confianza",
		valor8:"Calidad",
		valor9:"Iniciativa",
		seeMore: "See more" 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere1, setBeenHere1] = useState(false)
	const [ beenHere2, setBeenHere2] = useState(false)
	const [ beenHere3, setBeenHere3] = useState(false)
	const [ beenHere4, setBeenHere4] = useState(false)
	const [ beenHere5, setBeenHere5] = useState(false)
	const [ beenHere6, setBeenHere6] = useState(false)
	const [ beenHere7, setBeenHere7] = useState(false)
	const [ beenHere8, setBeenHere8] = useState(false)
	const [ beenHere9, setBeenHere9] = useState(false)
	const [ beenHere10, setBeenHere10] = useState(false)
	const [ beenHere11, setBeenHere11] = useState(false)



	return(
			<div className={styles.Container} id="Historia">
				<div className={"MaxDimensions"} style={{position:"relative"}}>
					<Flex flexDirection={["column","column","column"]} >

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere1(true)
								return(
									<motion.div className={styles.Flex1} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere1) ? 1 : 0, x: (isVisible || beenHere1) ? 0 : -1000, }} transition={{ duration: 1 }}>
										<Flex flexDirection="column" display={["none !important","none !important","flex !important"]} width={["100%","100%","50%"]} justifyContent={["flex-start"]}>
											<Flex sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
												<Box className={styles.AbsoluteBoxL} />
												<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={Nosotros2}/>
											</Flex>
										</Flex>
										<Flex flexDirection="column" width={["100%","100%","50%"]}  justifyContent={["flex-start","flex-start","flex-start"]}>
											<Flex justifyContent="center" alignItems="flex-start">
												<Text
													fontWeight="900" 
													textAlign="left" 
													fontSize={[45,65]} 
													color="#2E333E">{trans.title}</Text>
											</Flex>
											<Flex flexDirection="column" mt={[30]} mb={[70]} justifyContent="center" alignItems="flex-start" pl={[0,0,70]}>
												<Text
													width={["100%","100%"]}
													fontWeight={300}
													mx="auto"
												 	mb={[30]}
													textAlign="left" 
													color="#2E333E"
													f
													lineHeight="28px"
													fontSize={[16]}>{trans.subtitle}</Text>
												<Text
													width={["100%","100%"]}
													fontWeight={300}
													mx="auto"
												 	mb={[30]}
													textAlign="left" 
													color="#2E333E"
													lineHeight="28px"
													fontSize={[16]}>{trans.subtitle1}</Text>
												<Text 
													width={["100%","100%"]}
													mx="auto"
													fontWeight={300}
												 	mb={[30]}
													textAlign="left" 
													color="#2E333E"
													lineHeight="28px"
													fontSize={[16]}>{trans.subtitle2}</Text>
												<Text 
													width={["100%","100%"]}
													mx="auto"
													fontWeight={300}
												 	mb={[0]}
													textAlign="left" 
													color="#2E333E"
													lineHeight="28px"
													fontSize={[16]}>{trans.subtitle3}</Text>
											</Flex>
										</Flex>
									</motion.div>
							)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere2(true)
								return(
							<motion.div className={styles.Flex2} initial={{ opacity: 0, y: -1000 }} animate={{ opacity: (isVisible || beenHere2) ? 1 : 0, y:  (isVisible || beenHere2) ? 0 : -1000, }} transition={{ duration: 1 }}>
								<Flex flexDirection="column" width={["100%","100%","100%"]} pr={[0,0,0]}>
									<Text
										textAlign="center" 
									 	width={["100%","100%"]}
									 	mx="auto"
									 	lineHeight="35px"
									 	fontWeight={700}
										color="#2E333E"
										fontSize={[20,25]}>{trans.subtitle4}</Text>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere3(true)
								return(
							<motion.div  className={styles.Flex3} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere3) ? 1 : 0, x:  (isVisible || beenHere3) ? 0 : -1000, }} transition={{ duration: 1 }}>
								<Box className={styles.AbsoluteBoxR} />
								<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" height="400px" src={Nosotros3} mt={80} />
								<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" height="400px" src={Nosotros4} mb={80} ml={54} mr={"-15px"} />
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere4(true)
								return(
							<motion.div className={styles.Flex4} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere4) ? 1 : 0, y:  (isVisible || beenHere4) ? 0 : 1000, }} transition={{ duration: 1 }}>
									<Box className={styles.AbsoluteBoxR} />
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={Nosotros3}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={Nosotros3}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={Nosotros3}/>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere5(true)
								return(
							<motion.div className={styles.Flex5} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere5) ? 1 : 0, y: (isVisible || beenHere5) ? 0 : 1000 }} transition={{ duration: 1 }}>
								<Flex flexDirection="column" width={["100%","100%","100%"]} pr={[0,0,0]}>
									<Text
										textAlign="center" 
									 	width={["100%","100%"]}
									 	mx="auto"
									 	lineHeight="35px"
									 	fontWeight={300}
										color="#2E333E"
										fontSize={[20,25]}>{trans.subtitle4}</Text>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere6(true)
								return(
							<motion.div className={styles.Flex6} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere6) ? 1 : 0, x: (isVisible || beenHere6) ? 0 : -1000 }} transition={{ duration: 1 }}>
								<Flex maxHeight={['450px', '600px', 'auto']} display={["flex !important","flex !important","none !important"]} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
									<Box className={styles.AbsoluteBoxL} />
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={Nosotros4}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={Nosotros4}/>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere7(true)
								return(
							<motion.div className={styles.Flex7} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere7) ? 1 : 0, y: (isVisible || beenHere7) ? 0 : 1000 }} transition={{ duration: 1 }}>
								<Flex mb={[0,0,150]} flexDirection={["column","column","row"]} >
									<Flex maxHeight={['450px', '600px', 'auto']} display={["none !important","none !important","flex !important"]}  sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","50%"]}>
										<Box className={styles.AbsoluteBoxL} />
										<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={Nosotros5}/>
									</Flex>
									<Flex pl={[0,0,70]} flexDirection="column" my={[70]} mt={[90,90,70]} width={["100%","100%","50%"]} justifyContent="center" alignItems="flex-start">
										<Text 
										 	width={["100%","100%"]}
											mx="auto"
											fontWeight="900" 
										 	mb={[40]}
											textAlign="left" 
											color="#2E333E"
											fontSize={[45,65,70]}>{trans.subtitle5}</Text>
										<Text 
										 	mb={[0]}
										 	width={["100%","100%"]}
										 	mx="auto"
										 	fontWeight={300}
											textAlign="left" 
											color="#2E333E"
											lineHeight="28px"
											fontSize={[16]}>{trans.subtitle6}</Text>
									</Flex>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere8(true)
								return(
							<motion.div className={styles.Flex8} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere8) ? 1 : 0, x: (isVisible || beenHere8) ? 0 : -1000 }} transition={{ duration: 1 }}>
								<Flex maxHeight={['450px', '600px', 'auto']} display={["flex !important","flex !important","none !important"]} sx={{position:"relative"}} justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
									<Box className={styles.AbsoluteBoxR} />
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={Nosotros5}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={Nosotros5}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={Nosotros5}/>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere9(true)
								return(
							<motion.div className={styles.Flex9} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere9) ? 1 : 0, x: (isVisible || beenHere9) ? 0 : -1000 }} transition={{ duration: 1 }}>
								<Flex mb={[0,0,150]} flexDirection={["column","column","row"]} >
									<Flex flexDirection="column" pr={[0,0,70]} my={[70]} mt={[90,90,70]} justifyContent="center" alignItems="flex-start" width={["100%","100%","50%"]}>
										<Text
											width={["100%","100%"]}
											mx="auto"
											fontWeight="900" 
										 	mb={[40]}
											textAlign="left" 
											color="#2E333E"
											fontSize={[45,65,70]}>
										 	{trans.subtitle7}
										 	</Text>
										<Text 
										 	mb={[0]}
										 	width={["100%","100%"]}
										 	mx="auto"
										 	fontWeight={300}
											textAlign="left" 
											color="#2E333E"
											lineHeight="28px"
											fontSize={[16]}>{trans.subtitle8}</Text>
									</Flex>
									<Flex sx={{position:"relative"}} maxHeight={['450px', '600px', 'auto']} display={["none !important","none !important","flex !important"]} justifyContent="center" py={[40]} width={["100%","100%","50%"]}>
										<Box className={styles.AbsoluteBoxR} />
										<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="DeskOnly" width="100%" src={Nosotros6}/>
									</Flex>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere10(true)
								return(
							<motion.div className={styles.Flex10} initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere10) ? 1 : 0, x: (isVisible || beenHere10) ? 0 : -1000 }} transition={{ duration: 1 }}>
								<Flex maxHeight={['450px', '600px', 'auto']} sx={{position:"relative"}} display={["flex !important","flex !important","none !important"]}  justifyContent="center" py={[40]} width={["100%","100%","100%"]}>
									<Box className={styles.AbsoluteBoxL} />
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={Nosotros6}/>
									<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={Nosotros6}/>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

						<TrackVisibility partialVisibility>		
							{({ isVisible }) => {
								if(isVisible)
									setBeenHere11(true)
								return(
							<motion.div className={styles.Flex11} initial={{ opacity: 0, y: 1000 }} animate={{ opacity: (isVisible || beenHere11) ? 1 : 0, y: (isVisible || beenHere11) ? 0 : 1000 }} transition={{ duration: 1 }}>
								<Flex flexDirection="column" my={[70]} justifyContent="center" alignItems="flex-start">
									<Text
										width={["100%","100%"]}
										mx="auto"
										fontWeight="900" 
									 	mb={[42]}
										textAlign="left" 
										color="#2E333E"
										fontSize={[45,65,70]}>{trans.subtitle9}</Text>
									<Flex flexWrap="wrap" justifyContent={["space-around","flex-start"]}>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor1}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor2}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor3}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor4}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor5}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor6}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor7}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor8}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" >{trans.valor9}</Flex>
										<Flex alignItems="center" mr="20px" justifyContent="center" bg="#EDEEEF" mb="20px" width="145px" height="45px" sx={{visibility: 'hidden'}}>{trans.valor9}</Flex>
									</Flex>
								</Flex>
							</motion.div>
						)}}
						</TrackVisibility>

					</Flex>
				</div>
			</div>
	);
}

export default Historia;