import React, { useEffect, useState } from 'react';
import {GridLightBox} from '../../../components/LightBox/grid'
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';

import Cover from './Cover';
import styles from './Proyecto.module.css';


const Proyecto = (props) => {

	const [gallery, setGallery] = useState([])
	console.log("not_proyect",gallery)

	useEffect(() => {
		if(props.proyect){
			let _gallery = []
			console.log("proyect")
			props.proyect.images.map((file, index) => {
				_gallery.push({
					src: file,
					index:index
		    	})
			})
			setGallery(_gallery)
		}
	}, [props.proyect])

	return(
		<React.Fragment>
			<Cover title={props.proyect?props.proyect.client:''} subtitle={props.proyect?props.proyect.type:''}  cover={props.proyect ? props.proyect.cover : null} description={props.proyect?props.proyect.description:''}/>
			<div className={styles.Container} id="Proyectos">
				<div className={"MaxDimensions"} style={{position:"relative"}}>
					<Flex flexDirection={["column"]} >
						<Flex flexDirection={["column"]}>
							<Flex flexDirection={["column","row"]} justifyContent={["flex-start"]} flexWrap="wrap">
								{gallery.length !== 0 ?
									// props.proyect.images.map((image)=>(
									// 	<Box mb={[36, 36]} py={[21, 21, 25]} px={[0,9,11]} width={[1, 1/2, 1/3]} sx={{position:"relative"}}>
									// 		<Box width="100%" height={[281,235,281]} sx={{backgroundImage:`url(${image})`,backgroundSize:"cover",backgroundPosition:"center",backgroundRepeat:"no-repeat"}}/>
									// 	</Box>
									// ))
									<Box width="100%">
										<Flex py={3} widht={"100%"} flexDirection="row" className="gallery-container">
											<GridLightBox sources={gallery} />
										</Flex>
									</Box>
									:null
								}
							</Flex>
						</Flex>
					</Flex>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Proyecto;