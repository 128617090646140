import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import styles from './Cover.module.css';
import coverMobile1 from "../../../assets/nosotros_coverMobile1.png"
import coverIPad1 from "../../../assets/nosotros_coverIPad1.png"
import coverDesk1 from "../../../assets/nosotros_coverDesk1.png"
import coverMobile2 from "../../../assets/nosotros_coverMobile2.png"
import coverIPad2 from "../../../assets/nosotros_coverIPad2.png"
import coverDesk2 from "../../../assets/nosotros_coverDesk2.png"

import servicios from "../../../assets/Covers/Servicios.jpg"

import { motion } from "framer-motion"

const Cover = (props) => {

	const windowWidth = window.innerWidth;
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title1: "Servicios",
		title2: "detalle",
		subtitle: "Idea Los Cabos se posiciona actualmente como una empresa líder en su ramo, con más de 16 años de experiencia, un equipo de más de 90 personas y con múltiples proyectos exitosos en la región para residencias, hoteles y constructoras.",
	 },
	 en: {
		title1: "Services",
		title2: "",
		subtitle: "Idea Los Cabos se posiciona actualmente como una empresa líder en su ramo, con más de 16 años de experiencia, un equipo de más de 90 personas y con múltiples proyectos exitosos en la región para residencias, hoteles y constructoras.", 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	return(
				<Flex flexDirection={["column"]} height="100%">
					<Flex flexDirection="column" bg="#15203C" mb={[0,100]}>
						<div className={styles.Container} id="Cover" style={{backgroundImage:`url(${servicios})`,backgroundSize:"cover"}}>
							<div className={"MaxDimensions"} style={{position:"relative",justifyContent:"center"}}>
									<motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
										<Text
											sx={{textShadow:"#00000052 0 9px 6px"}}
											display="flex"
											alignItems={["flex-end","center","flex-end"]}
											minHeight={["unset","220px","180px"]}
											mb={[0,100]}
											fontWeight="900" 
											textAlign="left" 
											fontSize={[50, 65, 86]} 
											color="white">{trans.title1}
										</Text>
									</motion.div>
									<motion.div initial={{ opacity: 0, y: -300 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
										<Box className={styles.IPadDeskAbsoluteCard} bg="#F4F5F7" width="90%" display={["none","block","none"]} >
											<Flex px={50} minHeight="200px" alignItems="center" justifyContent="center">
												<Text
													lineHeight="28px" 
													textAlign="left" 
													color="#2E333E"
													fontSize={[18,20]}>{trans.subtitle}</Text>
											</Flex>
										</Box>
										<Box className={styles.IPadDeskAbsoluteCard2} bg="#F4F5F7" width="calc(100% - 650px)" display={["none","none","block"]} >
											<Flex px={50} minHeight="250px" alignItems="center" justifyContent="center">
												<Text
													lineHeight="28px"
													textAlign="left" 
													color="#2E333E"
													fontSize={[18,20]}>{trans.subtitle}</Text>
											</Flex>
										</Box>	
									</motion.div>
							</div>
						</div>
					</Flex>
					<Flex flexDirection="column">
						<Flex display={["flex","none","none"]} bg="#F4F5F7" minHeight={[370]} alignItems="center" justifyContent="center">
							<div className={styles.Container} id="Cover" style={{paddingTop:0,paddingBottom:0,margin:"auto"}}>
								<div className={"MaxDimensions"} style={{position:"relative",justifyContent:"center"}}>
									<Text 
										lineHeight="28px"
										textAlign="left" 
										color="#2E333E"
										fontSize={[20]}>{trans.subtitle}</Text>
								</div>
							</div>
						</Flex>
					</Flex>
				</Flex>
	);
}

export default Cover;