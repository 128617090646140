import React from 'react';

// import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import styles from './SideDrawer.module.css';
import Backdrop from '../../UI/Backdrop/Backdrop';

import closeSideDraw from '../../../assets/closeSideDraw.svg'

const sideDrawer = props => {
	let attachedClassed = [styles.SideDrawer,styles.Close];
	if(props.open){
		attachedClassed = [styles.SideDrawer,styles.Open];
	}


	return (
	<React.Fragment>
		<Backdrop show={props.open} clicked={props.closed}/>
		<div className={attachedClassed.join(' ')}>
			<div className={styles.sideDrawerClose}>
				<img src={closeSideDraw} alt="" onClick={props.closed} style={{filter:"brightness(0)"}} />
			</div>
			<nav style={{height:"100%"}}>
				<NavigationItems closed={props.closed} scrollTo={props.scrollTo} ContactRef={props.ContactRef}/>
			</nav>
		</div>
	</React.Fragment>

	);
};

export default sideDrawer;