import React from "react";
import "./Loader.css";

const Loader = () => {
	return(
		<svg className="spinner" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
		  <circle cx="50" cy="50" r="45"/>
		</svg>
	)
}

export default Loader;