import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import styles from './Cover.module.css';

import coverMobile from "../../../assets/Portada1.jpg"
import coverIPad from "../../../assets/Home1-ipad.jpg"
import coverDesk from "../../../assets/Portada1.jpg" 

import coverMobile2 from "../../../assets/Portada2.jpg"
import coverIPad2 from "../../../assets/Home2-ipad.jpg"
import coverDesk2 from "../../../assets/Portada2.jpg"

import newHomeCover1 from "../../../assets/newHomeMobile.jpg"
import newHomeCover2 from "../../../assets/newHomeIPad.jpg"
import newHomeCover3 from "../../../assets/newHomeCover.png"
import downArrow from "../../../assets/downArrow.svg" 
import {isMobile} from 'react-device-detect'; 

import Carousel from "../../../components/Carousel/Carousel"

import { motion } from "framer-motion"

const Cover = (props) => {

	// useEffect(()=>{
	// 	if(isMobile && window.innerWidth < 600){
	// 	    var heights = window.innerHeight;
	// 		console.log("mobile",heights);
	// 	    document.getElementById("CoverMainPage").style.height = heights + "px";
	// 	}
	// },[])

	const windowWidth = window.innerWidth;
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title1: "Calidad en  ",
		title2: "cada ",
		title3: "detalle",
		subtitle: "Somos especialistas en servicios de plomeria y electricidad, así como en construcción y mantenimiento de albercas.",
	 },
	 en: {
		title1: "Quality in ",
		title2: "every ",
		title3: "detail",
		subtitle: "We are specialists in plumbing and electrical services, as well as in construction and maintenance of swimming pools.", 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	return(
			<Box className={styles.Container} id="CoverMainPage" sx={{backgroundImage: [`url(${newHomeCover1})`,`url(${newHomeCover2})`,`url(${newHomeCover3})`],backgroundSize:"cover",backgroundPositionX:["right","right","unset"]}}>
				<div className={"MaxDimensions"} style={{maxWidth:"1440px",position:"relative",justifyContent:"center"}}>
					<Image className="getArrowWide" onClick={props.scrollTo.Nosotros} src={downArrow} sx={{position:"absolute",bottom:40,left:"50%",transform:"translate(-50%,0)",cursor:"pointer",width:30}}/>
						<motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
							<Flex flexDirection={["column","column", "row"]} height="100%">
								<Flex flexDirection="column" justifyContent="center" pt="20px" pl={["15px", "50px", 0]} pr={["15px","50px",60]}  height="fit-content" width={["100%","100%","60%"]} margin={[0]}>
									<Flex  flexDirection="column" justifyContent="flex-start" alignItems="flex-start" >
										<Text
											fontWeight="900" 
											textAlign="left"
											sx={{textShadow:"#00000052 0 9px 6px"}} 
											fontSize={[45, 86, 86]} 
											color="white">{trans.title1}</Text>
										<Text
											fontWeight="900" 
											textAlign="left"
											sx={{textShadow:"#00000052 0 9px 6px"}} 
											fontSize={[45, 86, 86]} 
											color="white">{trans.title2}<span className={styles.italicUnderline}>{trans.title3}</span></Text>
										{/*<Text
											fontWeight="900" 
											fontSize={[45, 86, 86]} 
											sx={{textDecoration: "underline",fontStyle: "italic", display:['none', 'inline-block'], color:'white'}}
										>{trans.title3}</Text>*/}
									</Flex>
									<Flex  minHeight={[200, 160]} justifyContent="flex-start" alignItems="center">
										<Text 
											lineHeight="28px" 
											textAlign="left" 
											color="white"
											sx={{textShadow:"#00000052 0 9px 6px"}}
											fontSize={[18,22]}>{trans.subtitle}</Text>
									</Flex>
								</Flex>
								{/*<Flex flexDirection="column" height="100%" width={["100%","100%","60%"]}>
									<Carousel>
										<Flex>
											<Image className="MobileOnly" width="100%" src={coverMobile}/>
											<Image className="IPadOnly" width="100%" src={coverMobile}/>
											<Image className="DeskOnly" width="100%" src={coverDesk}/>
										</Flex>
										<Flex>
											<Image className="MobileOnly" width="100%" src={coverMobile2}/>
											<Image className="IPadOnly" width="100%" src={coverMobile2}/>
											<Image className="DeskOnly" width="100%" src={coverDesk2}/>
										</Flex>
									</Carousel>
								</Flex>*/}
							</Flex>
						</motion.div>
				</div>
			</Box>
	);
}

export default Cover;