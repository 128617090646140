import React, { useRef,useEffect } from 'react';

import styles from './Servicios.module.css'

import Layout from '../../components/Layout/Layout';
import Cover from '../../sections/Servicios/Cover/Cover';
import ServiciosList from '../../sections/Servicios/Servicios/Servicios';
import Footer from '../../sections/Home/Footer/Footer';
import Contacto from '../../sections/Home/Contacto/Contacto';

import { Fade } from "react-awesome-reveal"

const Servicios = (props) => {
	const ContactoRef = useRef(null)

	const toBottomContact = () => {
		window.scrollTo({top: (ContactoRef.current.offsetTop - 70)  ,behavior: 'smooth',})
	}
	
	return(
		<Fade delay={200}>
			<Layout scrollTo={{
				Contacto:toBottomContact,
			}}>
				<div className={styles.Servicios}>
					<div id="Cover">
					<Cover />
					</div>
					<div id="ServiciosList">
					<ServiciosList />
					</div>
					<div id="Contacto" ref={ContactoRef}>
					<Contacto  />
					</div>
					<div id="Footer">
					<Footer />	
					</div>
				</div>
			</Layout>
		</Fade>
	)	
};


export default Servicios;