import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import {
  Flex,
  Text,
  Image,
  Box
} from 'rebass';
import styles from './Cover.module.css';
import coverMobile1 from "../../../assets/nosotros_coverMobile1.png"
import coverIPad1 from "../../../assets/nosotros_coverIPad1.png"
import coverDesk1 from "../../../assets/nosotros_coverDesk1.png"
import coverMobile2 from "../../../assets/nosotros_coverMobile2.png"
import coverIPad2 from "../../../assets/nosotros_coverIPad2.png"
import coverDesk2 from "../../../assets/nosotros_coverDesk2.png"

//Nosotros
import NosotrosCover from "../../../assets/Nosotros/NosotrosCover.jpg"   
import Nosotros2 from "../../../assets/Nosotros/Nosotros2.jpg"  

import nosotros from "../../../assets/Covers/Nosotros.jpg"

import TrackVisibility from 'react-on-screen';
import { motion } from "framer-motion"

const Cover = (props) => {

	const windowWidth = window.innerWidth;
	const history = useHistory();

	let trans = new LocalizedStrings({
	 es:{
		title1: "Nosotros",
		title2: "detalle",
		subtitle: "Idea Los Cabos es una empresa especializada en servicios de plomeria y electricidad; construcción y mantenimiento de todo tipo de albercas; así como el suministro de equipos y accesorios.",
	 },
	 en: {
		title1: "About us",
		title2: "",
		subtitle: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmodLorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod", 
	 }
	});

	const urlLang = history.location.pathname.split("/")[1];
	trans.setLanguage(urlLang);

	const [ beenHere, setBeenHere] = useState(false)

	return(
				<Flex flexDirection={["column"]} height="100%">
					<Flex flexDirection="column"  mb={[0,100]}>
							<div className={styles.Container} id="Cover" style={{backgroundImage:`url(${nosotros})`,backgroundSize:"cover"}}>
								<div className={"MaxDimensions"} style={{position:"relative",justifyContent:"center"}}>
									<motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
										<Text
												sx={{textShadow:"#00000052 0 9px 6px"}}
												display="flex"
												alignItems={["flex-end","center","flex-end"]}
												minHeight={["unset","220px","180px"]}
												mb={[0,100]}
												fontWeight="900" 
												textAlign="left" 
												fontSize={[50, 65, 86]} 
												color="white">{trans.title1}</Text>
									</motion.div>
									<Box className={styles.IPadDeskAbsoluteCard} bg="#F4F5F7" width="90%" display={["none","block","none"]} >
										<Flex px={50} minHeight="200px" alignItems="center" justifyContent="center">
											<Text
												lineHeight="28px" 
												textAlign="left" 
												color="#2E333E"
												fontSize={[20]}>{trans.subtitle}</Text>
										</Flex>
									</Box>
									<motion.div initial={{ opacity: 0, y: -300 }} animate={{ opacity: 1, y: 0}} transition={{ duration: 1.2}}>
										<Box className={styles.IPadDeskAbsoluteCard2} bg="#F4F5F7" width="calc(100% - 650px)" display={["none","none","block"]} >
											<Flex px={50} minHeight="250px" alignItems="center" justifyContent="center">
												<Text
													lineHeight="28px"
													textAlign="left" 
													color="#2E333E"
													fontSize={[20]}>{trans.subtitle}</Text>
											</Flex>
										</Box>
									</motion.div>
								</div>
							</div>
					</Flex>
					<TrackVisibility partialVisibility>	
						{({ isVisible }) => {
						if(isVisible)
							setBeenHere(true)
						return(
							<motion.div initial={{ opacity: 0, x: -1000 }} animate={{ opacity: (isVisible || beenHere) ? 1 : 0, x: (isVisible || beenHere) ? 0 : -1000}} transition={{ duration: 2 }} style={{flexDirection:"column",display:"flex"}}>
								<Flex display={["flex","none","none"]} bg="#F4F5F7" minHeight={[300]} alignItems="center" justifyContent="center">
									<div className={styles.Container2} id="Cover">
										<div className={"MaxDimensions"} style={{position:"relative"}}>
											<Text 
												textAlign="left" 
												color="#2E333E"
												lineHeight="28px"
												padding={"0 15px"}
												fontSize={[20]}>{trans.subtitle}</Text>
										</div>
									</div>
								</Flex>
								<Flex bg="#FFFFFF" display={["flex","flex","none"]} minHeight={[370]} py={[60]} alignItems="center" justifyContent={["center","flex-start","center"]}>
									<div className={styles.Container2} id="Cover">
										<div className={"MaxDimensions"} style={{position:"relative"}}>
											<motion.div 
												initial={{ opacity: 0, y: -300 }} 
												animate={{ opacity: 1, y: 0}} 
												transition={{ duration: 1.2}}>
												<Flex sx={{position:"relative"}} maxHeight={['450px', '600px', 'auto']} justifyContent={["center","flex-start","center"]} py={[40]} width={["100%","100%","50%"]}>
													<Box className={styles.AbsoluteBoxL} />
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="MobileOnly" width="100%" src={Nosotros2}/>
													<Image height="auto" sx={{zIndex:2,objectFit:"cover"}} className="IPadOnly" width="100%" src={Nosotros2}/>
												</Flex>
											</motion.div>
										</div>
									</div>
								</Flex>
							</motion.div>
							)}}
					</TrackVisibility>	
				</Flex>
	);
}

export default Cover;