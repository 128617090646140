import React, { useState, Fragment } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

import { Flex } from 'rebass';

import {ItemLightBox} from './item'
import Grid from '..//Grid/Grid';
 
 
export const GridLightBox = ({sources}) => {  
    
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  
    const onGalleryOpen = (e) => {
      setPhotoIndex(e.currentTarget.id)
      setIsOpen(true)
    }      

    console.log(sources)

    return (
      <Fragment>

        <Grid elements={sources} config={{
            columns: [2, 3, 4],
            aspectRatio: "1:1",
            rowGutter: ["5px"],
            columnGutter: ["5px"],
            customItemStyles: {
                maxHeight: ["350px", "400px", "443px"],
                overflow:'hidden',
                cursor:'pointer',
                background:'#000',
            },
            customGridStyles:{
              width:"100%"
            },
            customItemContainerStyles:{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
            }
        }}>
            <ItemLightBox callback={onGalleryOpen} />
        </Grid>

 
        {isOpen && (
          <Lightbox
            mainSrc={sources[photoIndex].src}
            nextSrc={sources[(photoIndex + 1) % sources.length].src}
            prevSrc={sources[(photoIndex + sources.length - 1) % sources.length].src}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex( (photoIndex + sources.length - 1) % sources.length )
            }
            onMoveNextRequest={() =>
              setPhotoIndex( (photoIndex + 1) % sources.length )
            }
          />
        )}
      </Fragment>
    )
}